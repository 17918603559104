#release-notes {
  text-align: left;
  flex: 1;

  .content-container {
    display: flex;
    justify-content: space-between;

    .content {
      padding-right: 48px;
      flex: 5;
      color: #04246a;

      .category {
        margin-bottom: 48px;

        .header {
          font-size: 20px;
          margin-bottom: 4px;
          margin-top: -10px;

          &.large {
            margin-top: 0;
            font-weight: bold;
            font-size: 24px;
            font-family: Antonio-bold, sans-serif;
          }
        }

        .category-content {
          margin-left: 16px;
          padding-left: 16px;
          border-left: 2px solid #e20101;
        }

        .title {
          font-size: 20px;
          font-family: Antonio-bold, sans-serif;
          margin-top: 24px;
        }

        .subtitle {
          margin-top: 24px;
        }

        .message {
          line-height: 24px;
          font-size: 16px;
          margin-bottom: 4px;

          p {
            margin: 24px 0;
          }

          &.italic {
            font-style: italic;
          }
        }

        ul {
          padding: 0;

          li {
            margin: 0 0 6px 24px;
          }
        }
      }
    }
  }
}

@media (max-height: 900px) {
  #release-notes {
    .head {
      height: 100px;
    }
  }
}
