button.sign-in-button {
  text-transform: none;
  border-radius: 0;
  height: 43px;
  width: 143px;
  border: 1px solid #e20101;

  .sign-in {
    margin-left: 12px;
    text-align: center;
    color: #e20101;
  }
}
