.events-table-container {
  .events-table {
    margin-top: 24px;

    table {
      height: 100%;
    }

    .MuiTableRow-head {
      .MuiTableCell-root {
        border-bottom: 1px solid #b7c0d5;

        & > div {
          display: flex;
          align-items: center;
          column-gap: 8px;
        }
      }

      .dsca-type-cell {
        min-width: 13%;
      }

      .classifier-cell {
        min-width: 16%;
      }

      .empty-indicator-cell {
        min-width: 17%;
      }

      .document-type-cell {
        min-width: 15%;
      }

      .event-domain-cell {
        min-width: 15%;
      }

      .visibility-cell {
        min-width: 15%;
      }

      .related-events-cell {
        & > div {
          flex-wrap: wrap;
        }
      }

      svg {
        transition: opacity 0.3s;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        &.up {
          transform: rotate(-90deg);
        }

        &.down {
          transform: rotate(90deg);
        }
      }
    }

    .MuiTableCell-root {
      font-size: 16px;
      color: #6b7790;

      .separator {
        height: 100%;
        display: flex;
        align-items: center;
        border-left: 1px solid #a8b2cc;
        padding-left: 12px;
      }

      .capitalize {
        text-transform: capitalize;
      }

      #search-input {
        border: 1px solid #04246a;
        box-shadow: none;
        width: auto;
        input {
          padding: 8px 12px;

          &:focus-visible {
            outline: unset;
          }

          &:focus {
            border: 1px solid transparent;
          }
        }
        svg {
          right: 12px;
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:nth-child(odd) {
          background-color: #f8f9fa;
        }

        .MuiTableCell-body {
          color: #04246a;
        }
      }
    }
  }

  .no-event-found {
    margin-top: 0 !important;
    padding: 16px;
  }
}
