#top-menu {
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  background-color: #f8f9fa;
  font-size: 14px;
  text-align: right;
  padding: 8px 24px;

  .flag {
    cursor: pointer;
  }

  a,
  span {
    cursor: pointer;
    text-decoration: none;
    color: #04246a;

    &:hover {
      text-decoration: underline;
    }
  }

  &.phone,
  &.tablet {
    justify-content: center;
  }
}
