.child-partner-list {
  color: #04246a;

  .covered-products-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin: 8px 0 0 16px;
    text-align: left;

    .covered-products-title {
      font-weight: bold;
    }

    ul,
    li {
      padding: 0;
      margin: 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      column-gap: 12px;
      row-gap: 4px;
      line-height: 24px;

      li {
        list-style: none;

        .covered-product {
          display: flex;
          gap: 12px;

          .separator {
            border-right: 1px solid #b7c0d5;
          }
        }
      }
    }
  }

  .branch-list {
    margin-top: 0;
  }
}
