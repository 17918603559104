.offer-row {
  user-select: none;
  margin-top: 24px;
  padding: 16px;
  border-top: solid 1px #d9deea;
  flex-grow: 1;
  text-align: left;
  cursor: pointer;

  &.checked {
    background-color: #e6e9f0;

    .MuiTableCell-root {
      color: #04246a;

      .cell-content {
        border-left: 1px solid #04246a;
      }
    }

    &.custom td {
      border-bottom: none;
    }
  }

  .MuiTableCell-root {
    padding: 24px;
    font-size: 16px;
    color: #6b7790;

    &.empty {
      width: 0;
      padding: 0;
    }

    &.name-cell {
      width: 20%;
      font-weight: bold;
      font-size: 18px;
    }

    &.pricing-cell {
      width: 20%;

      .price {
        font-weight: bold;
        font-size: 20px;
      }

      .per-month {
        font-weight: normal;
      }
    }

    &.consumption-limit-cell,
    &.other-limit-cell,
    &.over-consumption-cell,
    &.pricing-cell {
      padding-left: 0;
    }

    .cell-content {
      border-left: 1px solid #6b7790;
      padding-left: 24px;
    }
  }

  &.five-col {
    .consumption-limit-cell {
      width: 20%;
    }

    .other-limit-cell,
    .over-consumption-cell {
      width: 20%;
    }
  }

  .MuiRadio-root {
    padding: 0 12px 2px 0;
  }
}

.offer-card {
  display: flex;
  padding: 16px;
  border: solid 1px #d9deea;
  cursor: pointer;
  margin-bottom: 10px;
  .cell {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #6b7790;
    .MuiIconButton-root {
      margin-right: 10px;
    }
    .cell-content {
      text-align: left;
      .name-cell,
      .price-cell,
      .consum-cell,
      .other-cell,
      .over-cell {
        padding: 10px;
      }
      .name-cell {
        font-weight: bold;
        font-size: 18px;
      }
      .price {
        font-weight: bold;
      }
    }
  }
  &.checked {
    background-color: #e6e9f0;
    .cell {
      color: #04246a;
    }
  }
  &.custom {
    margin-bottom: 0px;
    border-bottom: none;
  }
}

.custom-message-row {
  border-top-color: #ffffff;
  background-color: #e6e9f0;

  .MuiTableCell-root {
    padding: 0 16px 16px 56px;

    .label {
      color: #04246a;
    }

    .MuiFormControl-root {
      width: 100%;
      margin-top: 4px;

      .MuiInputBase-root {
        height: unset;
        padding: 0;

        textarea {
          height: 48px;
          min-height: 48px;
          max-height: 200px;
          padding: 8px 12px;
          color: #04246a;
          font-size: 16px;
          resize: vertical;

          &::placeholder {
            color: #6b7790;
            font-style: italic;
          }
        }
      }
    }
  }
}
