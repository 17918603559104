.event-metrics {
  .controls-container {
    align-items: center;
    color: #6b7790;

    .filter-select {
      border: 1px solid #04246a;
      padding: 0 12px;
      height: 42px;
    }

    .switch-container {
      display: flex;
      float: right;
      align-items: center;

      .MuiIconButton-label {
        color: white;
      }

      .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
        background-color: #235af6;
        opacity: 1;
      }
    }
  }

  #global-summary {
    margin-bottom: 24px;
  }

  .family-tiles-container {
    display: flex;
    gap: 24px;

    &.family-selected {
      gap: 0;
    }
  }

  &.phone,
  &.tablet {
    .controls-container {
      justify-content: center;
    }

    .family-tiles-container {
      flex-direction: column;

      &.family-selected {
        display: block;
      }

      .family-tile {
        max-width: 100%;
      }
    }
  }
}
