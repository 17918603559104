#productsRoadmap {
  color: #04246a;
  text-align: left;

  .content {
    background-color: #f8f9fa;

    .App-content {
      .block-contact {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        .content {
          text-align: left;
          font-size: 18px;
          margin: auto 0;
        }
        .MuiTabs-indicator {
          height:45px; /* Default height is 45px */
          width: 4px;
          background-color: #e20101;
          display:inline-block;
          margin-left: 0px;
          @media (min-width: 0px) and (max-width: 900px) {
            margin-left: 0px;
            height: 75px;
          }
          @media (min-width: 1300px) {
            margin-left: 15px;
            height: 45px;
          }
        }
        .grid-container-master {
          display: grid;
          gap: 1px; /* Gap between grid items */
          grid-template-columns: 1fr 5fr;
        }
        .grid-container-slave{
          display: grid;
          grid-template-columns: 1fr 20fr 1fr;
          margin-top: 0px;
          @media (min-width: 1300px) {
            margin-top:-10px;
          }
        }
        .button {
          background: #e20101;
          border-radius: 0;
          color: #ffffff;
          transition: background-color 0.3s;
          width: 140px;
          height: 40px;
          line-height: 40px;
          text-align: center;

          &:hover {
            cursor: pointer;
            background-color: #a92316;
          }
        }

        &.phone,
        &.tablet {
          display: inline-block;

          .button {
            margin-top: 20px;
          }
        }
      }
      .family {
        margin: 20px 0;
        .block-title {
          display: flex;
          justify-content: space-between;
          .family-title {
            display: flex;
            align-items: center;
            margin: 48px 0 44px;
            font: normal normal 26px/32px Antonio-Bold;
            color: #04246a;

            .title-brace {
              margin-right: 4px;
              font-size: 26px;
              color: #e20101;
            }
          }
          .MuiInputBase-root {
            margin: 48px 0 44px;
            color: #04246a;
            .MuiSelect-select {
              padding: 0 24px 0 4px;
            }
          }
        }

        .list {
          padding: 20px 0;
          display: grid;
          grid-template-columns: repeat(
            auto-fill,
            minmax(calc((100% - 88px) / 3), 1fr)
          );
          gap: 24px;

          .card {
            display: grid;
            grid-template-rows: auto 1fr auto;
            height: 260px;
            padding: 20px;
            background-color: #fff;

            .top {
              display: flex;
              justify-content: space-between;
              svg {
                cursor: pointer;
                margin-top: 2px;
                &:hover {
                  opacity: 0.7;
                }
              }
              .date {
                padding: 4px 10px;
                font-size: 18px;
                border-radius: 16px;
                text-transform: uppercase;
                text-align: center;

                &.imminent,
                &.recently-updated {
                  color: #00865d;
                  background-color: #00865d42;
                }

                &.coming-soon {
                  color: #3e6cd1;
                  background-color: #3e6dd142;
                }

                &.planned, &.pilot {
                  color: #fabf40;
                  background-color: #fabf4042;
                }
              }
            }

            .title {
              text-align: center;
              margin: auto 0;
              font: normal normal 24px/38px Antonio-Bold;
            }

            .description {
              text-align: center;
              font-size: 18px;
              line-height: 20px;
              margin-bottom: 20px;
              height: 80px;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }

          &.tablet {
            grid-template-columns: repeat(
              auto-fill,
              minmax(calc((100% - 64px) / 2), 1fr)
            );
          }

          &.phone {
            grid-template-columns: repeat(
              auto-fill,
              minmax(calc(100% - 40px), 1fr)
            );
          }
        }
      }
    }
  }
}
