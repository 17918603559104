#contact-us {
  flex: 1;
  display: flex;
  flex-direction: column;

  .braced-title {
    margin-bottom: 48px;
  }

  .content-container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      border-bottom: 1px solid #9aa7c4;

      .MuiTabs-flexContainer {
        gap: 48px;
      }

      .tab {
        cursor: pointer;
        user-select: none;
        padding: 16px 0;
        text-align: left;
        font-size: 20px;
        font-family: Antonio-Bold, sans-serif;
        text-transform: unset;
        color: #6b7790;
        z-index: 2;

        &.active {
          color: #04246a;
          border-bottom: solid 4px #e20101;
        }
      }

      .MuiTabs-indicator {
        height: 100%;
        z-index: 1;
        background-color: white;
      }

      .MuiTouchRipple-root > span {
        display: none;
      }
    }

    .content {
      flex-grow: 1;
      text-align: left;
      margin-top: 24px;
      color: #04246a;

      .send-request {
        font-weight: bold;
        margin: 24px 0 12px;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }

      p {
        margin-bottom: 32px;

        .title {
          margin-bottom: 8px;
          font-weight: bold;
        }

        li {
          margin-bottom: 4px;
        }
      }
    }
  }
}
