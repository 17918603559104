#menu {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-right: 1px solid #04246a;
  color: #04246a;
  min-width: 286px;

  .titleMenu {
    margin: 10px 24px;
    font-family: Antonio-Bold, sans-serif;
    text-align: left;
    font-size: 20px;
  }

  .MuiTabs-indicator {
    width: 4px;
    background-color: #e20101;
  }

  .br {
    border: solid 1px rgba(0, 0, 0, 0.2);
    margin: 12px 24px;
  }

  .tab {
    max-width: unset;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border-right: 2px solid transparent;
    line-height: unset;
    white-space: normal;
    text-transform: unset;
    font-weight: 400;
    opacity: 1;
    color: #04246a;

    &:hover {
      background: #e6e9f0 0 0 no-repeat padding-box;
      border-right: #e6e9f0;
    }

    .MuiTab-wrapper {
      align-items: flex-start;
      white-space: nowrap;
    }

    &.Mui-selected {
      font-weight: bold;
    }
  }
}
