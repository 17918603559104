.textarea-input {
  .MuiTextField-root {
    width: 100%;

    .MuiInputBase-root {
      display: flex;
    }

    .MuiInput-multiline {
      margin-bottom: 0;
      padding: 6px 12px;
      height: 136px;

      .MuiInputBase-inputMultiline {
        height: 116px;
        color: #04246a;
      }
    }
  }
}
