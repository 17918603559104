.select-input {
  .MuiInputBase-root {
    display: flex;

    .MuiInputBase-input.Mui-disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .MuiSelect-root {
    color: #04246a;
    padding: 0 12px;
    text-align: left;

    &:focus {
      background-color: #ffffff;
    }

    .disabled {
      opacity: 0.4;
    }
  }
}
