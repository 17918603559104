#functional-rules {
  margin-top: 48px;

  .functional-rules-table {
    margin-top: 16px;

    .MuiTableRow-head {
      .MuiTableCell-root {
        border-bottom: 1px solid #b7c0d5;
      }

      .type-cell,
      .value-cell,
      .date-cell {
        & > div {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }
      }

      .type-cell,
      .date-cell {
        width: 18%;
        min-width: 180px;

        & > div {
          column-gap: 8px;

          svg {
            cursor: pointer;
            height: 18px;
            width: 18px;
            transition: opacity 0.3s;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      .type-cell {
        .type-selection-container {
          position: relative;

          ul {
            position: absolute;
            top: -12px;
            left: 26px;
            display: none;
            background-color: #ffffff;
            margin: 0;
            padding: 6px 0;
            border-radius: 4px;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0px 8px 10px 1px rgba(0, 0, 0, 0.14),
              0px 3px 14px 2px rgba(0, 0, 0, 0.12);
            user-select: none;

            &.visible {
              display: block;
            }

            li {
              display: flex;
              justify-content: space-between;
              gap: 12px;
              list-style: none;
              margin: 0;
              padding: 6px 16px;
              cursor: pointer;
              text-transform: capitalize;
              color: #04246a;
              transition: background-color 0.3s;

              &:hover {
                background-color: rgba(0, 0, 0, 0.04);
              }

              svg {
                opacity: 0;
                transition: opacity 0.3s;

                &.visible {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .value-cell {
        min-width: 350px;

        & > div {
          flex-wrap: wrap;
          column-gap: 20px;
        }
      }

      .date-cell {
        svg {
          &.up {
            transform: rotate(-90deg);
          }

          &.down {
            transform: rotate(90deg);
          }
        }
      }

      .status-cell {
        width: 8%;
      }
    }

    .MuiTableCell-root {
      font-size: 16px;
      color: #6b7790;

      &.type {
        text-transform: capitalize;
      }

      &.no-rules {
        text-align: center;
      }

      & > div:not(.type-cell-content) {
        border-left: 1px solid #a8b2cc;
        padding-left: 12px;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:nth-child(odd) {
          background-color: #f8f9fa;
        }

        .MuiTableCell-body {
          color: #04246a;
        }

        .status {
          display: flex;
          justify-content: center;

          .status-content {
            width: max-content;
            background-color: #cce7df;
            color: #07865d;
            font-size: 12px;
            font-family: Roboto-Bold, sans-serif;
            letter-spacing: 0.7px;
            border-radius: 50px;
            border: 0;
            padding: 4px 10px;
            font-style: normal;
            text-align: center;
          }
        }

        &.planned,
        &.expired {
          .MuiTableCell-body {
            font-style: italic;
            color: #6b7790;
          }
        }

        &.planned {
          .status-content {
            background-color: #dff0ff;
            color: #0173e2;
          }
        }

        &.expired {
          .status-content {
            background-color: #f6e0e1;
            color: #e20101;
          }
        }
      }
    }
  }
  #search-input {
    border: 1px solid #04246a;
    box-shadow: none;
    width: auto;
    input {
      padding: 8px 12px;

      &:focus-visible {
        outline: unset;
      }

      &:focus {
        border: 1px solid transparent;
      }
    }
    svg {
      right: 12px;
    }
  }
}
