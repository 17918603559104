#api-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100vh;

  .back-container {
    background-color: #f8f9fa;
    padding: 16px 0;
    width: auto;

    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #235af6;
      font-size: 16px;
      transition: opacity 0.3s;

      &.App-content {
        padding: 0 24px;
      }

      & > span {
        margin-top: 2px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  & > .custom-scrollbars {
    flex-grow: 1;
  }

  .App-content {
    padding: 24px;

    .not-found {
      z-index: -1;
    }

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        font-family: Antonio-Bold, sans-serif;
        font-size: 26px;
        color: #04246a;
      }

      .name {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: 8px;
        font-family: "Roboto Light", sans-serif;
        font-size: 16px;
        color: #04246a;
      }

      .view-swagger {
        align-self: flex-start;
        width: max-content;
        padding: 12px 24px;
        cursor: pointer;
        background-color: #e20101;
        color: #ffffff;
        transition: background-color 0.3s;

        &:hover {
          background-color: #a92316;
        }
      }
    }

    .tags-container {
      margin-top: 24px;
      display: flex;
      gap: 96px;

      .tag {
        font-size: 16px;
        font-weight: bold;

        .tag-title {
          color: #04246a;
          font-family: Roboto, sans-serif;
          letter-spacing: -0.49px;
        }

        .tag-content {
          width: max-content;
          margin-top: 8px;
          color: #ffffff;
          background-color: #0173e2;
          padding: 3px 6px;
          border-radius: 7.5px;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.5px;
          text-transform: uppercase;
        }
      }
    }

    .description,
    .release {
      margin-top: 48px;

      .release-content {
        margin-top: 22px;
      }
    }

    .link {
      margin: 48px 0;
      .link-content {
        margin-top: 22px;
        .link-title {
          color: #0173e2;
          display: flex;
          justify-content: space-between;
          padding: 10px 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          cursor: pointer;
          svg {
            color: #04246a;
          }
        }
        .linkResponse {
          color: #04246a;
          margin: 36px 32px;
        }
      }
    }

    .description-content,
    .no-method-found {
      margin-top: 24px;
      color: #04246a;
      font-size: 16px;
    }

    .segment,
    .methods,
    .loader {
      margin-top: 48px;
    }

    .methods {
      #methods-table {
        margin-top: 24px;

        .MuiTableRow-head {
          .MuiTableCell-root {
            border-bottom: 1px solid #b7c0d5;
          }

          .method-cell {
            width: 10%;
          }
          .name-cell {
            width: 35%;
          }
          .summary-cell {
            width: 40%;
          }
          .security-cell {
            width: 15%;
          }
        }

        .MuiTableCell-root {
          font-size: 16px;
          color: #6b7790;

          div {
            border-left: 1px solid #a8b2cc;
            padding-left: 12px;
          }
        }

        .MuiTableBody-root {
          .MuiTableRow-root {
            &:nth-child(odd) {
              background-color: #f8f9fa;
            }

            .MuiTableCell-body {
              color: #04246a;
            }
          }
        }
      }
    }

    .label {
      color: #04246a;
      margin-bottom: 12px;
    }

    &.phone,
    &.tablet {
      .tags-container {
        gap: 24px;
      }
    }
  }
}
