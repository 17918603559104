#new-entity {
  .two-block {
    margin-bottom: 24px;
    display: grid;
    justify-content: space-between;
    gap: 24px;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc((100% - 28px) / 2), 1fr)
    );
  }

  .buttons-container {
    margin-top: 48px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    column-gap: 32px;
    row-gap: 16px;

    .button {
      padding: 8px 16px;
      width: 100%;
    }

    .submit-button {

      &.disabled {
        background-color: #e2afaf;
        border: 1px solid #e2afaf;
        cursor: default;
      }

      .loader {
        display: flex;

        .MuiCircularProgress-root {
          color: #c19b9b !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  #new-entity {
    .two-block {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
  }
}

@media only screen and (max-width: 550px) {
  #new-entity {
    .two-block {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .buttons-container {
      margin-top: 24px;
    }
  }
}
