.input-label {
  display: flex;
  align-items: center;
  gap: 6px;
  text-align: start;
  margin-bottom: 12px;
  color: #04246a;
  font-size: 16px;

  svg {
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;
  }

  .error-icon {
    color: #d02d4f;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
