.loader {
  margin: 0 auto;
  width: max-content;
  height: 100%;
  display: flex;
  align-items: center;
}

.center-loader {
  position: absolute;
  top:50%;
  left:50%;
}

.waiting-loader{
  display: inline;
  position: relative;
  top: 5px;
  left: 10px;
}