.messages-count {
  .count-container {
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .title {
      margin-top: auto;
      margin-bottom: auto;
      color: #6b7790;
    }

    .count {
      color: #04246a;
    }
  }

  .progress-bar-custom {
    width: 100%;
    height: 5px;
    background-color: #e6e9f0;
  }

  .progress {
    height: 5px;
  }
}
