#catalog {
  flex: 1;
  display: flex;
  flex-direction: column;

  .header-content {
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 10%;
    gap: 10%;
    padding: 0 48px;
  }

  & > .content {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    background-color: #f8f9fa;

    .grid-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      & > .App-content {
        padding: 0;
      }
    }
  }
}
