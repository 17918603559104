#business-partners .partner-list .list-header .button.export-csv {
  &.loading {
    opacity: 0.7;
  }

  .loader .MuiCircularProgress-root svg {
    color: #c19b9b;
  }

  &.computer {
    width: 113px;
    padding: 0;
  }
}
