.offer-line {
  border-bottom: 1px solid #04246a2d;
  padding-top: 24px;
  .offer-tile {
    display: flex;
    padding-bottom: 20px;
  }

  .offer-name-cell,
  .delete-cell {
    width: 10%;
  }

  .info-package-cell {
    width: 70%;
    display: flex;
  }

  .custom-package-cell{
    width: 80%;
  }

  .package-name-cell {
    // margin: auto;
    // padding-left: 0;
    // width: 10%;
    margin-right: 1rem;
    white-space: nowrap;
    font-family: Roboto-Bold, sans-serif;
  }

  .price-cell {
    margin: auto 0;
    padding-right: 20px;
    width: 15%;
  }

  .delete-cell {
    width: 7%;
  }

  .custom-message-cell {
    width: 70%;
  }

  .offer-name-cell,
  .custom-message-cell,
  .offer-desc-cell,
  .delete-cell {
    margin: auto 0;
    border-left: 1px solid #04246a;
    padding-left: 24px;
  }

  .custom-message {
    color: #6b7790;
    font-style: italic;
  }

  .offer-desc {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .price-element {
    display: flex;
    color: #04246a;
    font-size: 16px;

    .value {
      font-weight: bold;
    }

    .unit {
      margin-left: 4px;
    }
  }

  .delete {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #0173e2;
    font-size: 16px;
    user-select: none;

    & > svg {
      margin-left: 4px;
      height: 20px;
      width: 20px;
      fill: #0173e2;
    }

    &:hover {
      opacity: 0.7;
    }
  }
  .infos-detail {
    display: flex;
  }

  .separator {
    background-color: #04246a;
    width: 8px;
    height: 1px;
  }

  &.tablet {
    .package-name-cell {
      width: 40%;
    }
    .price-cell {
      width: 35%;
      .price-element {
        display: flex;
        justify-content: flex-start;
      }
    }
    .delete-cell {
      width: 20%;
    }
    .info-package-cell {
      .offer-desc-cell,
      .custom-message-cell {
        border: none;
      }
      .offer-name-cell,
      .separator {
        display: none;
      }
      .cell-content {
        display: unset;
      }
      .info {
        margin-bottom: 10px;
      }
    }
    .infos-detail {
      gap: 40px;
      .offer-name-cell,
      .custom-message-cell {
        border: none;
        margin-bottom: 10px;
      }
    }
  }

  &.phone {
    .offer-tile {
      justify-content: space-between;
      .package-name-cell,
      .delete-cell {
        border: none;
        margin: 0;
        width: auto;
      }
    }
    .infos-detail {
      margin-bottom: 20px;
      justify-content: space-between;
      .info-package-cell,
      .price-cell {
        width: auto;
        .price-element {
          display: flex;
          justify-content: flex-start;
        }
      }
      .offer-name-cell,
      .custom-message-cell {
        border: none;
        width: auto;
      }
    }
  }
  &.computer {
    height: 60px;
  }
}

.custom-offers {
  .offer-line:last-child {
    border-bottom: 0;
  }
}
