#privacy-notice {
  flex: 1;
  text-align: justify;

  ul {
    padding: 0;

    li {
      margin-left: 24px;
    }
  }

  .title-header {
    margin-bottom: 24px;
  }

  .category {
    &:not(:first-child) {
      margin-top: 48px;
    }

    .title {
      margin-bottom: 24px;
    }

    .message {
      line-height: 24px;
      font-size: 16px;
      color: #04246a;

      p {
        margin: 24px 0;
      }
    }

    .link {
      color: #002ff2;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .MuiTableContainer-root {
      margin: 24px 0;
      max-width: 800px;

      .MuiTableBody-root .MuiTableRow-root {
        &:nth-child(odd) {
          background-color: #f8f9fa;
        }

        .MuiTableCell-body {
          color: #04246a;
        }
      }

      &.one-row {
        .MuiTableBody-root .MuiTableRow-root {
          background-color: #ffffff;
        }

        .MuiTableCell-root {
          border-top: 1px solid rgba(224, 224, 224, 1);
        }

        .MuiTableCell-body.first-cell {
          color: #6b7790;
        }
      }

      .MuiTableRow-head {
        .method-cell {
          width: 10%;
        }
        .name-cell {
          width: 35%;
        }
        .desc-cell {
          width: 55%;
        }
      }

      .MuiTableCell-head {
        border-width: 2px;
      }

      .MuiTableCell-root {
        font-size: 16px;
        color: #6b7790;

        &.first-cell {
          width: 30%;
        }

        div {
          border-left: 1px solid #a8b2cc;
          padding-left: 12px;
        }
      }
    }
  }

  #ot-sdk-cookie-policy,
  #ot-sdk-cookie-policy-v2 {
    font-family: Roboto, sans-serif !important;

    #cookie-policy-title {
      margin: 36px 0 24px;
    }
  }

  #ot-sdk-cookie-policy,
  #ot-sdk-cookie-policy-v2,
  .ot-sdk-cookie-policy-group,
  .ot-sdk-cookie-policy-group-desc,
  .ot-table-header,
  .ot-host-td,
  .ot-cookies-td,
  .ot-cookies-type {
    font-size: 16px !important;
  }
}
