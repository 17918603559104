#user-subscribed {
  width: max-content;
  color: white;
  background-color: #00865d;
  padding: 3px 6px;
  border-radius: 7.5px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
