.api-grid {
  $transition-timer: 0.3s;
  padding: 20px 40px;

  &.computer {
    padding: 44px;
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .sort-container {
      display: flex;

      #name-sort {
        user-select: none;
        cursor: pointer;

        .icon-container {
          width: 24px;
          margin-right: -12px;

          .MuiSvgIcon-root {
            margin: 3px auto -3px -16px;

            &.asc {
              transform: rotate(-90deg);
            }

            &.desc {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  .category {
    text-align: left;
    margin: 0 20px;

    .title-category {
      font-size: 18px;
      margin-bottom: 12px;
      color: #04246a;
    }

    .MuiInputBase-root {
      width: 100%;

      .MuiSelect-select {
        margin-left: 20px;
        color: #04246a;

        &:focus {
          background-color: #ffffff;
        }
      }
      .category {
        margin: 24px 0 24px 0;
      }
    }
  }

  .list-container {
    position: relative;
    flex-grow: 1;

    &.empty {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .list {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      justify-content: space-between;
      gap: 24px;
    }

    .notfound {
      .message {
        color: #6b7790;
      }

      .image {
        width: 64px;
        height: 64px;

        .a {
          fill: #f8f9fa;
        }

        .b,
        .c {
          fill: #6b7790;
        }

        .content1,
        .font1 {
          stroke: #6b7790;
        }
      }
    }
  }

  &.phone,
  &.tablet {
    .category {
      margin: 0 0 24px 0;
    }
  }
}
