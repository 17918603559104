$transition-timer: 0.3s;

.family-tile {
  position: relative;
  flex: 1;
  background-color: #f8f9fa;
  max-width: 33.333%;
  height: 236px;
  overflow: hidden;

  &.no-checked {
    opacity: 0.5;
  }

  &.expanded {
    max-width: 100%;
    height: 649px;
  }

  &.collapsed {
    max-width: 0;
    height: 0;
    opacity: 0;
  }

  .padding-div {
    height: calc(100% - 48px);
    padding: 24px 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .open-modal-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  .label-container {
    display: flex;
    justify-content: flex-end;
    height: 24px;
    opacity: 1;

    .label {
      border-radius: 16px;
      width: 55px;
      height: 20px;
      line-height: 20px;

      background-color: #dff0ff;
      text-transform: uppercase;
      color: #0173e2;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .tile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 42px;

    .families-names {
      display: flex;
      gap: 24px;

      .name {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #04246a;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .is-not-selected {
        opacity: 0.5;
        cursor: pointer;
      }
    }

    .controls {
      display: flex;
      align-items: center;

      .expand-icon {
        cursor: pointer;
        color: #04246a;
        opacity: 1;
        transition: opacity 0.3s;

        &:hover:not(.disabled) {
          opacity: 0.7;
        }
      }

      .MuiButtonBase-root {
        transition: opacity 0.3s;

        &:hover:not(.Mui-disabled) {
          opacity: 0.7;
        }

        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.38);
          cursor: not-allowed;
        }

        .MuiTouchRipple-root > span {
          display: none;
        }
      }
    }
  }

  &.phone {
    min-height: auto;
  }
}
