.profile-content {
  background-color: #e6e9f0;
  & > .App-content {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .details,
  .apiCredentials,
  .commercialSubscription,
  .eventWebhook,
  .usersManagement {
    background-color: #fff;
    border: 1px solid #04246a20;
    color: #04246a;
    padding: 30px;
    cursor: pointer;
    .header {
      display: flex;
      justify-content: space-between;
      .braced-title {
        font-size: 24px;
      }
    }
    .content {
      margin-left: 48px;
      text-align: left;
      .clientRequestName,

      .map-div{
        margin: 24px 0px 24px -40px;
      }

      .title {
        font-size: 20px;
        font-weight: bold;
        font-size: 18px;
        margin: 24px 0px 24px -40px;
        text-align: left;
        color:#E20101;
      }
        .apiSecretLabel{
          display: flex;
          flex-direction: column;
          margin-right: 32px;
          .apiSecretValue {
            font-size: 15px;
            color:#00865D;
          }
          .apiSecretError {
            font-size: 15px;
            font-weight: 700;
            color:red;
          }
        }
      .toast{
        position: fixed;
        top:2%;
        left:46%;
        color:#e20101;
        z-index: 13;
      }
      .redBorder{
        border: solid 1px #e20101;
      }
      .blueBorder{
        border: solid 1px #04246a;
      }
      .fullOpacity{
        opacity:1;
      }
      .halfOpacity{
        opacity:0.5;
      }
      .redLabel{
        color:#e20101;
      }
      .greenLabel{
        color:#00865D;
      }
      .blueLabel{
        color:#04246a;
      }
      #secret{
        font-size:17px
      }
      .copy-icon{
         padding-left:10px;
         width:0.8em;
         height:0.8em;
      }
      .receivedConfirmation{
        width:fit-content;
        margin: 0 auto;
        margin-top: 1rem;
      }
      .confirmationCodeMessage{
        text-align: center;
        width: 40vw;
      }
      .confirmationCodeDiv{
        font-size:18px;
      }
      .confirmationCodeContainer{
        div{
          .confirmationCodeInputLabel{
            font-weight: bold;
          }
          .codeInputContainer{
            display: flex;
            margin-top: 5px;
            
    justify-content: center;
            input{
              width: 20vw;
              border-color: #04246a;
            }
            .submitButton{
              padding: 8px 13px;
              height: 91.5%;
              &>*>*,
              &>* {
                color: #04246a;
              }
            }
            .clickableButton:active {
              opacity: 0.5;
            }
          }
        }
      }
      .apiCredentialsButtonsRow {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 45px;
        border-radius: 10px;
        padding: 40px 80px;
        .filledButton{
          color: #fff;
          padding: 20px 80px;
          background: #e20101;
          border: 1px solid #e20101;
          &>*{
            color: white;
          }
          &:hover {
            background: #e20101;
          }
        }
        .outlinedButton {
          @media (max-width:400px){
            width: 35vw;
            padding:5px 7px ;
          }
          padding: 10px 40px;
          background: white;
          border: 1px solid #e20101;
          &>*{
            color: #e20101;
          }
          &:active{
            opacity: 0.5;
          }
        }
        .disabledButton{
          pointer-events: none;
        }
        .applicationName{
          position: absolute;
          top:0;
          left:40px;
          margin:-10px 0;
          background-color: white;
          padding: 0 15px;
        }
      }

      .clientAddress,
      .contacts-content {
        margin: 12px 0 0 12px;
        text-align: left;
      }
      a {
        text-decoration: underline;
        color: #235af6;
      }
      .line {
        color: #04246a;
        padding: 10px 10px;

        

        &.even {
          background-color: #f8f9fa;
        }

        .name {
          display: flex;
          gap: 5px;
          font-weight: bold;
          font-size:18px;
          color: #04246a;
        }
        .family-name{
          display: inline;
          @media(max-width:459px){
            font-weight: bold;
            font-size: 18px;
            color: #04246a;
          }
        }
        .role,
        .email {
          color: #04246a;
          text-align: left;
          font-size:18px;
          margin-top:5px;
          text-overflow: ellipsis;overflow:hidden
        }
      }
      .newContact {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
        svg {
          margin: 8px;
          height: 100%;
          color: rgba(0, 0, 0, 0.38);
        }
      }
      .danger-zone {
        border: 1px solid #e20101;
        color:white;
        background-color: #E20101;
        margin-left: -10px;
        margin-right: 10px;
        text-align: center; /* Center the content horizontally */
        font-size: 20px;
        padding: 20px 40px;
        align-items: center;
        @media (max-width:420px){
          padding:20 80;
        }
        .delete-account {
          background-color: #e20101;
          color: #fff;
          max-width: 100%; /* Ensure the width doesn't exceed the parent's width */
          margin: 0 auto;
          padding: 20px 80px;
          @media (max-width:620px){
            padding: 10px 20px;
            font-size:11px;
          }
        }
      }
      .reset-credentials{
        font-size: 20px;
        margin-left: -10px;
        padding: 15px 0px;
        text-align: center;
        width: 100%;
        margin-top: 5px;
      }
    }
  }
}

.listUser{
  padding: 0 24px;
}
.listContact{
  padding: 0 24px;
}

.buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 32px;
  row-gap: 16px;

  .button {
    padding: 8px 16px;
    width: 100%;
  }

  .submit-button {
    width: 142px;
    height: 43px;

    &.disabled {
      background-color: #e2afaf;
      border: 1px solid #e2afaf;
      cursor: default;
    }

    .loader {
      display: flex;

      .MuiCircularProgress-root {
        color: #c19b9b !important;
      }
    }
  }
}

#new-customer{
  height: calc(470px - 2 * 48px);
  max-height: calc(100vh - 2 * 48px);
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #ffffff;
  padding: 48px;

  &.enriching-partner {
    height: calc(370px - 2 * 48px);
  }

  &:focus-visible {
    outline: unset;
  }

  &.form {
    .content-container {
      position: relative;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .title {
        margin-bottom: 24px;

        .MuiTabs-flexContainer {
          gap: 48px;
        }

        .tab {
          cursor: default;
          user-select: none;
          padding: 0;
          text-align: left;
          font-size: 20px;
          font-family: Antonio-Bold, sans-serif;
          text-transform: unset;
          color: #6b7790;
          z-index: 2;
          opacity: 1;

          &.active {
            color: #04246a;
          }
        }

        .MuiTabs-indicator {
          height: 100%;
          z-index: 1;
          background-color: white;
        }

        .MuiTouchRipple-root > span {
          display: none;
        }

        .info {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 14px;
          color: #04246a;

          svg {
            color: #0173e2;
          }
        }
      }

      .content {
        margin-right: 16px;
        color: #04246a;

        .send-request {
          font-weight: bold;
          margin: 24px 0 12px;
        }

        a {
          text-decoration: underline;
          cursor: pointer;
        }

        p {
          margin-bottom: 32px;

          .title {
            margin-bottom: 8px;
            font-weight: bold;
          }

          li {
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  &.success {
    left: calc(50% - 225px - 48px);
    top: 25%;
    width: 450px;
    height: auto;
    color: #04246a;
    display: flex;
    flex-direction: column;

    .title {
      font-family: Antonio-bold, sans-serif;
      font-size: 26px;
      text-align: left;
    }

    .content {
      display: flex;

      svg {
        margin-right: 10px;
        width: 48px;
        height: 48px;
      }

      .content-text {
        display: grid;
        font-size: 16px;
        text-align: left;
      }
    }

    .button-container {
      display: flex;
      justify-content: center;

      .button {
        width: 120px;
      }
    }
  }

  &.phone,
  &.tablet {
    padding: 24px;
    height: calc(100vh - 48px);
    width: calc(100vw - 48px);
    max-height: 100vh;
  }
}

.name{
  font-size:18px;
  font-weight: 700;
  color: #04246A;
  text-align: left;
  max-height: 60px;
}
.email{
  margin-top:5px;
  font-size:18px;
  font-weight: 400;
  color: #04246A;
}
.blue-button{
  background-color: #0A2071;
  border:none
}
.fixed-toast{
    position: fixed;
    z-index: 1000; /* Ensure it's above other elements */
    right: 20px;
    top: 10px;
}
.authentication-container {
  margin-left: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
}

.authentication-container b {
  color: #333;
}

.authentication-label {
  font-weight: bold;
}
.autentication-value{
  overflow: auto;
}
.webhook-header {
  font-size: 18px;
  font-weight: bold;
}

.webhook-mappers {
  font-size: 14px;
  color: #04246a;
}

.webhook-url,
.authentication-type {
  margin: 8px 0;
  font-size: 14px;
}
