#event-applications {
  flex: 1;
  background-color: #e6e9f0;

  .App-content {
    padding: 32px 0;

    #event-content {
      background-color: #ffffff;
      padding: 48px;

      & > .not-found {
        position: relative;

        span {
          margin-top: 0;
        }
      }

      .free-trial-message {
        color: #d4d4d4;
        font-weight: bold;
        line-height: 24px;
      }
    }
  }

  &.phone {
    .App-content {
      padding: 8px 12px;

      #event-content {
        padding: 24px;
      }
    }
  }
}
