#packages {
  .block {
    margin-top: 48px;

    .title {
      font-family: Antonio-Bold, sans-serif;
      font-size: 22px;
      text-align: left;
    }

    .block-content {
      margin-top: 24px;
    }

    .information {
      text-align: justify;
      text-justify: auto;
    }

    .products {
      margin-top: 12px;

      .apis-container {
        padding: 24px;
        background-color: #e6e9f0;
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(calc((100% - 96px) / 5), 1fr)
        );
        gap: 24px;

        .packages-api-tile {
          min-width: 50px;
          position: relative;
          padding: 16px;
          background-color: #ffffff;
          border: 2px solid #ffffff;
          transition: border 0.3s;
          text-align: left;
          cursor: pointer;
          user-select: none;

          &:hover {
            border: 2px solid #3e64b8;
          }

          .family-name {
            font-size: 14px;
            margin-bottom: 4px;
          }

          .name {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: -0.49px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
      }
    }
  }
  &.phone {
    .block {
      .block-content {
        &.products {
          .apis-container {
            grid-template-columns: repeat(
              auto-fill,
              minmax(calc(100% - 24px), 1fr)
            );
          }
        }
      }
    }
  }
  &.tablet {
    .block {
      .block-content {
        &.products {
          .apis-container {
            grid-template-columns: repeat(
              auto-fill,
              minmax(calc((100% - 96px) / 2), 1fr)
            );
          }
        }
      }
    }
  }
}
