.partner-tooltip-container {
  position: relative;

  .partner-tooltip-container-popup {
    position: absolute;
    top: -6px;
    left: 32px;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 5px 10px 2px rgba(6, 22, 56, 0.1);
    color: #6b7790;
    font-size: 16px;
    cursor: default;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    & > svg {
      cursor: pointer;
      color: #6b7790 !important;
      fill: #6b7790 !important;
      opacity: 1;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
