#event-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  height: 100vh;

  .back-container {
    background-color: #f8f9fa;
    padding: 16px 0;

    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #235af6;
      font-size: 16px;
      transition: opacity 0.3s;

      &.App-content {
        padding: 0 24px;
      }

      & > span {
        margin-top: 2px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  & > .custom-scrollbars {
    flex-grow: 1;
  }

  .App-content {
    padding: 24px;

    .not-found {
      z-index: -1;
    }

    .header {
      display: flex;
      justify-content: space-between;

      .title {
        font-family: Antonio-Bold, sans-serif;
        font-size: 26px;
        color: #04246a;
      }

      .view-swagger {
        align-self: flex-start;
        width: max-content;
        padding: 12px 24px;
        cursor: pointer;
        background-color: #e20101;
        color: #ffffff;
        transition: background-color 0.3s, color 0.3s;

        &:hover {
          background-color: #a92316;
        }
      }
    }

    .tag {
      font-size: 16px;
      font-weight: bold;

      .tag-title {
        color: #04246a;
        font-family: Roboto, sans-serif;
        letter-spacing: -0.49px;
      }

      .tag-content {
        margin-top: 8px;
        color: #ffffff;
        background-color: #0173e2;
        width: max-content;
        padding: 4px 10px;
        line-height: 17px;
        border-radius: 7.5px;
        text-transform: uppercase;
      }
    }

    .update {
      margin-top: 24px;

      .update-title {
        color: #6b7790;
      }

      .update-date {
        margin-top: 8px;
        color: #04246a;
        font-size: 16px;
      }
    }

    .description {
      margin-top: 40px;
    }

    .description-content,
    .no-method-found {
      margin-top: 24px;
      color: #04246a;
      font-size: 16px;
    }

    .available-events {
      margin-top: 48px;
    }

    .label {
      color: #04246a;
      margin-bottom: 12px;
    }
  }
}
