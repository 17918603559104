.profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .trial-version {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-right: 24px;
    border-radius: 7.5px;
    padding: 0 12px;

    & > span {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 14px;
      text-transform: uppercase;
    }

    &.ready {
      background-color: #dff0ff;

      & > span {
        color: #235af6;
      }
    }

    &.ended {
      background-color: #f9ccdb;

      & > span {
        color: #e2014b;
      }
    }
  }

  .profile {
    display: flex;
    position: relative;

    .profile-button {
      cursor: pointer;
    }

    .circle-name {
      line-height: 40px;
      border: 1px solid #04246a;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }

    .profile-popup {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: max-content;
      right: 0;
      top: calc(100% + 22px);
      z-index: 12;
      background: #ffffff;
      padding: 24px 48px;
      box-shadow: 0 5px 10px #0616381a;
      color: #04246a;

      .name {
        margin-top: 12px;
        font-family: RobotoCondensed-Bold, sans-serif;
      }

      .disconnect {
        margin-top: 12px;
        color: #ffffff;
        background: #04246a;
        border-radius: 0;
        text-transform: none;
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
          background: #031745;
        }
      }
    }
  }

  .header-bottom-sign {
    display: flex;
    gap: 16px;

    .getting-stated-button {
      text-transform: none;
      border-radius: 0;
      height: 43px;
      width: 150px;
      background: #e20101 0 0 no-repeat padding-box;

      .sign-up {
        text-align: right;
        color: #ffffff;
      }

      &:hover {
        background-color: #a92316;
      }
    }
  }

  &.tablet,
  &.phone {
    .profile {
      .profile-button {
        border: 1px solid #ffffff;
      }
    }

    .header-bottom-sign {
      gap: 8px;
    }
  }
}
