#my-selection {
  margin-top: 48px;
  color: #04246a;

  .title {
    font-size: 28px;
    text-align: left;
    font-family: Antonio-Bold, sans-serif;
    margin-bottom: 48px;
  }

  .offers {
    .not-found {
      position: relative;
      margin: 48px 0;
    }

    .offers-type-title {
      color: #04246a;
      font-family: Antonio-bold, sans-serif;
      font-size: 20px;
      letter-spacing: -0.61px;
      text-align: left;
      margin-bottom: 24px;
    }

    .info-message {
      display: flex;
      gap: 12px;
      color: #04246a;
      font-size: 14px;
      margin-bottom: 12px;
      text-align: left;
    }

    .offers-tile {
      background-color: #ffffff;
      border: 2px solid #d9deea;
      padding: 0 24px;
      text-align: left;
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 48px;
      }

      .total-price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0;
        font-size: 20px;
        word-wrap: break-word;
        .total,
        .price {
          font-weight: bold;
        }
      }

      .total-message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 12px 0;
        font-size: 20px;
        word-wrap: break-word;
        .total,
        .message{
          max-width: 90%;
          font-weight: bold;
        }
      }
    }
    &.phone {
      .offers-tile {
        .price {
          display: unset;
        }
      }
    }
  }
}
