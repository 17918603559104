.transition-content {
  position: relative;

  .visible-content {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;
  }

  .placeholder-content {
    position: absolute;
    top: 0;
    width: 100%;
    user-select: none;
    opacity: 0;
    z-index: -2;
  }

  .content {
    padding-top: 8px;
  }
}
