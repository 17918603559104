.request-submitted {
  margin: auto;
  text-align: center;

  .title {
    font-size: 36px;
    color: #04246a;
    font-family: Antonio-bold, sans-serif;
    margin-bottom: 24px;
  }

  .content {
    font-size: 22px;
    color: #04246a;
    margin-bottom: 48px;
  }

  .button-container {
    display: flex;
    justify-content: center;

    .button {
      text-align: center;
      color: #ffffff;
      padding: 4px 64px;
      line-height: 51px;
      background-color: #e20101;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #a92316;
      }
    }
  }
}
