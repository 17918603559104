#site-map {
  flex: 1;

  .content {
    text-align: justify;
    color: #04246a;
    font-size: 16px;
    line-height: 24px;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 270px);
    justify-content: space-between;
    gap: 24px;

    ul {
      padding: 0;

      li {
        margin-left: 24px;

        a {
          color: #002ff2;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
