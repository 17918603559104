div[role="presentation"] {
  display: flex;
  justify-content: center;
  align-items: center;

  #new-bp {
    height: calc(470px - 2 * 48px);
    max-height: calc(100vh - 2 * 48px);
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #ffffff;
    padding: 48px;

    &.enriching-partner {
      height: calc(370px - 2 * 48px);
    }

    &:focus-visible {
      outline: unset;
    }

    &.form {
      .content-container {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .title {
          margin-bottom: 24px;

          .MuiTabs-flexContainer {
            gap: 48px;
          }

          .tab {
            cursor: default;
            user-select: none;
            padding: 0;
            text-align: left;
            font-size: 20px;
            font-family: Antonio-Bold, sans-serif;
            text-transform: unset;
            color: #6b7790;
            z-index: 2;
            opacity: 1;

            &.active {
              color: #04246a;
            }
          }

          .MuiTabs-indicator {
            height: 100%;
            z-index: 1;
            background-color: white;
          }

          .MuiTouchRipple-root > span {
            display: none;
          }

          .info {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 14px;
            color: #04246a;

            svg {
              color: #0173e2;
            }
          }
        }

        .content {
          margin-right: 16px;
          color: #04246a;

          .send-request {
            font-weight: bold;
            margin: 24px 0 12px;
          }

          a {
            text-decoration: underline;
            cursor: pointer;
          }

          p {
            margin-bottom: 32px;

            .title {
              margin-bottom: 8px;
              font-weight: bold;
            }

            li {
              margin-bottom: 4px;
            }
          }
        }
      }
    }

    &.success {
      left: calc(50% - 225px - 48px);
      top: 25%;
      width: 450px;
      height: auto;
      color: #04246a;
      display: flex;
      flex-direction: column;
      gap: 48px;

      .title {
        font-family: Antonio-bold, sans-serif;
        font-size: 26px;
        text-align: left;
      }

      .content {
        display: flex;

        svg {
          margin-right: 10px;
          width: 48px;
          height: 48px;
        }

        .content-text {
          display: grid;
          font-size: 16px;
          text-align: left;
        }
      }

      .button-container {
        display: flex;
        justify-content: center;

        .button {
          width: 120px;
        }
      }
    }

    &.phone,
    &.tablet {
      padding: 24px;
      height: calc(100vh - 48px);
      width: calc(100vw - 48px);
      max-height: 100vh;
    }
  }
}
