.header-banner {
  width: 100%;
  height: 69px;
  display: flex;
  flex-direction: row;
  align-items: center;

  background-image: url(../../../../assets/images/banner_other.jpg);
  background-size: cover;
  background-position: top right;
  background-repeat: no-repeat;

  &.homepage {
    background-image: url(../../../../assets/images/banner_homepage.jpg);
    background-position-x: left;
    height: 363px;
  }

  .header-content {
    color: #04246a;
    font-size: 28px;
    font-family: Antonio-bold;
    text-align: left;
  }
}
