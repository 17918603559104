.toast-alert {
  user-select: none;
  align-items: center;
  gap: 12px;

  & > div {
    padding: 0;
  }

  &.MuiAlert-root,
  .MuiAlert-icon {
    color: #ffffff !important;
  }

  .MuiAlert-icon {
    margin: 0;
  }

  &.MuiAlert-standardSuccess {
    background-color: #3a8360;
  }

  &.MuiAlert-standardInfo {
    background-color: #2c74db;
  }

  &.MuiAlert-standardWarning {
    background-color: #f2c059;

    &,
    .MuiAlert-icon {
      color: #000000 !important;
    }
  }

  &.MuiAlert-standardError {
    background-color: #d02d4f;
  }
}
