#search {
  position: relative;
  width: 30%;

  &.from-home-page {
    margin-top: 4px;
    width: unset;

    input {
      visibility: hidden;
      width: 0;
      transition: width 0.5s;
    }
  }
}
