#link {
  .link-content {
    margin-top: 22px;
    .link-title {
      color: #0173e2;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      cursor: pointer;
      svg {
        color: #04246a;
      }
    }
    .link-response {
      color: #04246a;
      margin: 12px 28px;
    }
  }
}
