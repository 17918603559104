#report-issue-container {
  & > .loader {
    margin-top: 48px;
  }

  .form-content {
    text-align: left;
    height: max-content;

    .MuiFormGroup-root {
      width: 100%;
      margin: 24px 0 0;
      opacity: 1;
      color: #04246a;
      flex-wrap: unset;
      gap: 24px;

      .inputs-container {
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(calc((100% - 72px) / 4), 1fr)
        );
        justify-content: space-between;
        gap: 24px;

        &.phone {
          grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

          & > div {
            width: 100%;
          }
        }

        &.tablet {
          grid-template-columns: repeat(
            auto-fill,
            minmax(calc((100% - 40px) / 2), 1fr)
          );
        }
      }

      .file-upload-container {
        .file-upload {
          display: flex;
          align-items: flex-start;

          .upload-button {
            flex-grow: 1;
            margin-right: 24px;
            max-width: 190px;
            padding: 12px 24px;
            cursor: pointer;
            transition: background-color 0.3s;

            svg {
              margin-left: 0;
            }

            &:hover {
              background-color: #04246a;
              color: #ffffff;
            }
          }

          .selected-files-container {
            flex: 5;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            padding-top: 6px;

            .selected-file {
              display: flex;
              align-items: center;
              gap: 4px;
              padding: 4px 8px 4px 12px;
              border: 1px solid #6b7790;
              border-radius: 8px;
              cursor: default;

              .size {
                opacity: 0.4;
              }

              .remove-icon {
                cursor: pointer;
                transition: opacity 0.3s;

                &:hover {
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }

      .MuiAutocomplete-clearIndicator {
        display: none;
      }
    }

    .field-require-info {
      display: flex;
      gap: 4px;

      .MuiSvgIcon-root {
        color: #04246a;
        height: 18px;
        width: 18px;
        margin-top: -1px;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
          background-color: unset;
          opacity: 0.7;
        }
      }
    }

    .button-container {
      margin-top: 48px;
      display: flex;
      gap: 24px;

      &.save-button {
        justify-content: flex-end;
      }

      .button {
        padding: 0;
        width: 86px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        color: #ffffff;
        background-color: #e20101;
        cursor: pointer;

        transition: background-color 0.3s;

        &:hover {
          background-color: #a92316;
        }

        &.disabled {
          background-color: #e2afaf;
          cursor: default;
        }

        .loader {
          display: flex;

          .MuiCircularProgress-root {
            color: #c19b9b !important;
          }
        }
      }
    }
  }

  .br {
    margin-bottom: 10px;
  }
}
