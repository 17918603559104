#usageGuide {
  text-align: left;

  .content-container {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    .custom-scrollbars {
      min-height: calc(100vh - 250px);
      height: unset !important;
    }

    .content {
      padding-right: 48px;
      flex: 5;

      ul {
        margin: 0;
        padding: 0;

        li {
          margin-left: 24px;
        }
      }

      .message-head {
        color: #04246a;
      }

      .title-header {
        margin-bottom: 24px;
      }

      .category {
        &:not(.api-access):not(.header-api) {
          margin-top: 48px;
        }

        .title {
          font-size: 24px;
          color: #04246a;
          font-family: Antonio-bold, sans-serif;
          margin-bottom: 24px;
        }

        .subcategory {
          margin-top: 24px;

          .braced-title {
            margin-bottom: 12px;
          }
        }
        
        .message {
          line-height: 24px;
          font-size: 16px;
          color: #04246a;

          p {
            margin: 24px 0;
          }
        }

        .tile-message {
          margin-top: 24px;
          padding: 16px 0;
          border-radius: 5px;
          display: flex;
          align-items: center;
          background-color: rgb(222, 235, 255);

          svg {
            margin: 0 24px;
          }

          .contentMessage {
            text-align: left;
            color: #04246a;
            line-height: 18px;
            flex: 1;
          }
        }

        .subTitle {
          font-size: 16px;
          font-family: Antonio-bold, sans-serif;
          color: #04246a;
          margin: 24px 0 12px;
        }

        .note {
          margin-top: 24px;
          background-color: #dff0ff;
          border-radius: 6px;
          padding: 24px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #04246a;

          svg {
            margin-right: 10px;
          }
        }

        .code {
          background-color: #f2f4f7;
          border-radius: 6px;
          display: flex;
          gap: 24px;
          line-height: 24px;
          font-family: Roboto, sans-serif;
          font-size: 16px;
          color: #6b7790;
          margin-bottom: 12px;

          .number {
            background-color: #eceff3;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;

            .number-content {
              margin: 8px 12px;
            }
          }

          .number,
          .code-content {
            margin-top: auto;
            margin-bottom: auto;
            white-space: pre-wrap;
          }
        }

        .braced-title.large {
          font-size: 20px;
          color: #04246a;
        }

        .api-url {
          color: #04246a;
          font-weight: bold;
        }

        .link {
          color: #002ff2;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .errorCode {
          font-family: Roboto-Bold, sans-serif;
          letter-spacing: 0.7px;
          border-radius: 50px;
          padding: 2px 8px;
          color: #e20101;
          background-color: #f6e0e1;
        }

        .successCode {
          font-family: Roboto-Bold, sans-serif;
          letter-spacing: 0.7px;
          border-radius: 50px;
          padding: 2px 10px;
          color: #07865d;
          background-color: #dfeeea;
        }

        .MuiTableContainer-root {
          margin: 24px 0;
          max-width: 800px;

          .MuiTableBody-root .MuiTableRow-root {
            &:nth-child(odd) {
              background-color: #f8f9fa;
            }

            .MuiTableCell-body {
              color: #04246a;
            }
          }

          &.one-row {
            .MuiTableBody-root .MuiTableRow-root {
              background-color: #ffffff;
            }

            .MuiTableCell-root {
              border-top: 1px solid rgba(224, 224, 224, 1);
            }

            .MuiTableCell-body.first-cell {
              color: #6b7790;
            }
          }

          .MuiTableRow-head {
            .method-cell {
              width: 10%;
            }
            .name-cell {
              width: 35%;
            }
            .desc-cell {
              width: 55%;
            }
          }

          .MuiTableCell-head {
            border-width: 2px;
          }

          .MuiTableCell-root {
            font-size: 16px;
            color: #6b7790;

            &.first-cell {
              width: 30%;
            }

            div {
              border-left: 1px solid #a8b2cc;
              padding-left: 12px;
            }
          }
        }
      }
    }

    .navigation {
      align-self: flex-start;
      flex: 1;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
      border-right: 1px solid #04246a;

      a {
        text-decoration: none;
      }

      .PrivateTabIndicator-colorSecondary-3 {
        width: 4px;
      }

      .jss4 {
        width: 4px;
      }

      .MuiButtonBase-root {
        text-align: left;
        color: #04246a;
        opacity: 1;
        border-right: 2px solid transparent;
        font-size: 16px;
        font-weight: 400;
        width: 100%;

        &.active,
        &.title {
          font-weight: bold;
        }

        .MuiTab-wrapper {
          text-transform: none;
          align-items: flex-start;
          line-height: 24px;
        }
      }
    }

    .grey-background{
      background-color: #6b77902e;
      padding: 2px 5px;
    }
  }
}

@media (max-height: 900px) {
  #usageGuide {
    .head {
      height: 100px;
    }

    .content-container .content {
      max-height: calc(100vh - 205px);
    }
  }
}
