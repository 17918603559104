.child-partner-item {
  .loa-button {
    svg {
      height: 26px;
      width: 26px;
    }
  }

  .sub-content-container {
    .address-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      justify-content: space-between;
      column-gap: 32px;
      row-gap: 16px;

      .address-item {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .address-item-title {
          font-weight: bold;
        }
      }
    }
  }

  .address-item-content {
    text-transform: capitalize;
  }
  .name{
    
    overflow: auto;
    @media(min-width:1300px){
      max-width: 350px;
    }
    @media(min-width:450px) and (max-width:500px){
      max-width: 250px;
    }
    @media(min-width:375px) and (max-width:450px){
      max-width: 170px;
    }
  }
}
