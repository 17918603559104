.metrics-chart {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 350px;
  margin: 24px 0;

  .apexcharts-legend {
    max-width: calc(100% - 100px);
  }

  .chart-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.7);

    .not-found span {
      margin-top: 0;
    }
  }
}
