.sort-alphabetically {
  color: #04246a;

  .label-inline {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }

    svg {
      margin: 0 -8px;
      height: 32px;
      width: 32px;
      transform: rotate(90deg);
    }

    .az-container {
      display: flex;
      flex-direction: column;
      margin-top: 2px;

      span {
        line-height: 14px;
      }
    }
  }
}
