.terms-conditions {
  color: #235af6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.noValid {
  opacity: 0.4;
}
