.period-filter-container {
  position: relative;
  cursor: pointer;
  user-select: none;

  .items-container {
    position: absolute;
    top: 100%;
    z-index: 1;
    .item-check{
      background-color: #d9deea;
      border-left: 1px solid #04246a;
      border-right: 1px solid #04246a;
      color: #04246a;
      text-align: left;
      font-size: 16px;
      width: 190px;
      padding: 8px 12px;
      transition: background-color 0.3s, color 0.3s;
      border-bottom: 1px solid #04246a;
      font-weight: bold;
      display:flex;
    }
    .item {
      background-color: #ffffff;
      border-left: 1px solid #04246a;
      border-right: 1px solid #04246a;
      color: #04246a;
      text-align: left;
      font-size: 16px;
      width: 190px;
      padding: 8px 12px;
      transition: background-color 0.3s, color 0.3s;

      &:last-child {
        border-bottom: 1px solid #04246a;
      }

      &.selected {
        background-color: #d9deea;
        font-weight: bold;
      }

      &:not(.selected):hover {
        background-color: #0173e2;
        color: #ffffff;

        .react-datetimerange-picker__calendar-button {
          svg {
            stroke: #ffffff;
          }
        }
      }

      .item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .item-dates {
        display: flex;
        justify-content: space-between;
        margin-top: 4px;
        font-weight: normal;
      }
    }

    .react-datetimerange-picker {
      .react-datetimerange-picker__wrapper {
        border: 0;

        .react-datetimerange-picker__inputGroup,
        .react-datetimerange-picker__range-divider,
        .react-datetimerange-picker__inputGroup,
        .react-datetimerange-picker__clear-button {
          display: none;
        }

        .react-datetimerange-picker__calendar-button {
          padding: 0;

          svg {
            height: 18px;
            width: 18px;
            stroke: #04246a;
            transition: stroke 0.3s, opacity 0.3s;
          }

          &:hover {
            svg {
              opacity: 0.7;
            }
          }
        }
      }

      .react-datetimerange-picker__calendar {
        inset: calc(100% + 36px) -13px auto auto !important;
        width: max-content;

        .react-calendar {
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
          border: 0;
          padding: 16px 24px 24px 24px;
          width: 314px;

          .react-calendar__navigation {
            button {
              display: flex;
              justify-content: center;
              align-items: center;
              background: #ffffff;
              color: #04246a;
              font-family: Antonio-Bold, sans-serif;
              letter-spacing: -0.61px;
              font-size: 20px;
              text-transform: capitalize;
              transition: background-color 0.3s, color 0.3s;

              svg {
                transition: fill 0.3s;
              }

              &:enabled:hover {
                color: #235af6;

                svg {
                  fill: #235af6;
                }
              }

              &.react-calendar__navigation__arrow {
                font-size: 20px;
              }

              &.react-calendar__navigation__prev2-button,
              &.react-calendar__navigation__next2-button {
                display: none;
              }

              &.react-calendar__navigation__next-button {
                transform: rotate(180deg);
              }
            }
          }

          .react-calendar__month-view__weekdays {
            cursor: default;

            abbr {
              text-decoration: unset;
              color: #6b7790;
            }
          }

          .react-calendar__tile {
            background: #ffffff;
            color: #04246a;
            padding: 0;
            height: 36px;
            width: 36px;
            font-size: 16px;
            border: solid 2px transparent;
            transition: border-color 0.3s;

            &.react-calendar__month-view__days__day--neighboringMonth {
              color: #9aa6c4;
            }

            &.react-calendar__tile--active {
              background: #d9deea;
              transition: background-color 0.3s, color 0.3s;
            }

            &.react-calendar__tile--rangeStart,
            &.react-calendar__tile--rangeEnd {
              background: #235af6;
              font-weight: bold;
              color: #ffffff;
            }

            &:hover,
            &:focus {
              border-color: #04246a;
            }

            &.react-calendar__year-view__months__month {
              background: #ffffff;
              color: #04246a;
              text-transform: capitalize;
              transition: color 0.3s;

              &:hover {
                color: #235af6;
                border-color: transparent;
              }
            }
          }
        }
      }

      &.phone {
        .react-datetimerange-picker__calendar {
          inset: calc(100% + 31px) -170px auto !important;
        }
      }
    }
  }
}
