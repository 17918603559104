.status-label {
  display: flex;
  gap: 4px;
  background-color: #cce7df;
  color: #07865d;
  font-size: 12px;
  font-family: Roboto-Bold, sans-serif;
  letter-spacing: 0.7px;
  border-radius: 50px;
  padding: 4px 10px;

  &.pending {
    background-color: #dff0ff;
    color: #0173e2;
  }

  &.loarequired {
    background-color: #ffebdf;
    color: #e27201;
  }

  &.rejected {
    background-color: #f6e0e1;
    color: #e20101;
  }

  &.small-phone {
    height: 22px;
    width: 22px;
    padding: 0;
    border: 1px solid #07865d;

    &.pending {
      border-color: #0173e2;
    }

    &.loarequired {
      border-color: #e27201;
    }

    &.rejected {
      border-color: #e20101;
    }
  }
}
