#business-partners {
  .button-full-width{
    width:100%;
  }
  background-color: #e6e9f0;

  & > .App-content {
    padding: 32px 0;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .back-container {
    user-select: none;
    background-color: #f8f9fa;
    padding: 16px 0;
    width: auto;

    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #235af6;
      font-size: 16px;
      transition: opacity 0.3s;

      &.App-content {
        padding: 0 24px;
      }

      & > span {
        margin-top: 2px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .free-trial-message {
    color: #d4d4d4;
    font-weight: bold;
    line-height: 24px;
  }

  .free-trial-message,
  .partner-list {
    background-color: #ffffff;
    padding: 20px;

    .chevron {
      transition: transform 0.3s;

      &.opened {
        transform: rotate(-180deg);
      }
    }

    .list-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .left-container,
      .right-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .left-container,
      .right-container {
        height: 36px;
      }

      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: max-content;
        text-align: center;
        color: #ffffff;
        padding: 0 12px;
        font-size: 14px;
        cursor: pointer;
        background-color: #04246a;
        transition: opacity 0.3s;

        &.phone {
          width: 36px;
          height: 36px;
          padding: 0;

          svg {
            color: #ffffff !important;
            fill: #ffffff !important;
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .label-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      margin-top: 3px;
    }

    .loader {
      align-self: center;
      margin: 16px auto;
    }

    .not-found {
      position: relative;

      span {
        margin-top: 0;
      }
    }

    .branch-list,
    .pagination-container {
      width: 100%;
    }

    .branch-list {
      &.open-request {
        margin-top: 32px;
      }

      .partner-item {
        display: flex;
        flex-direction: column;
        color: #04246a;
        padding: 16px 24px;

        &:not(:last-child) {
          border-bottom: 1px solid #b7c0d5;
        }

        &.even {
          background-color: #f8f9fa;
        }

        .chevron {
          transform: rotate(0);

          &.opened {
            transform: rotate(-180deg);
          }
        }

        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.clickable {
            cursor: pointer;
          }

          .left-content,
          .right-content {
            display: flex;
            align-items: center;
            gap: 8px;
            height: 32px;

            .MuiIconButton-root {
              padding: 0;
            }
          }

          .right-content {
            .children-button {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }

        .sub-content-container {
          padding-left: 32px;
          text-align: left;
        }
      }
    }

    .pagination-container {
      margin-top: 16px;
      display: flex;
      justify-content: center;

      .MuiButtonBase-root {
        color: #04246a;
      }

      .MuiPaginationItem-page.Mui-disabled {
        opacity: 0.7;
      }
    }
  }

  svg {
    color: #04246a;
    fill: #04246a;
  }

  &.tablet,
  &.phone {
    .App-content {
      width: calc(100% - 48px);
    }
  }

  &.phone {
    .partner-list {
      padding: 24px;

      .list-header {
        display: flex;
        justify-content: space-between;

        .left-container,
        .right-container {
          gap: 8px;
        }
      }

      .label-container {
        margin: 8px 0 0 16px;
      }

      .branch-list {
        &.open-request {
          margin-top: 16px;
        }

        .partner-item {
          padding: 12px;
          font-size: 14px;

          .left-content {
            flex-shrink: 1;
            overflow: hidden;

            .name {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .right-content {
            flex-shrink: 0;
          }
        }
      }
    }
  }
  .column-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto;
  }
  .row-grid{
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto auto;
  }
}
