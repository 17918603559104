#legal-terms {
  flex: 1;

  .content {
    text-align: justify;
    color: #04246a;
    font-size: 16px;
    line-height: 24px;

    p {
      margin: 8px 0;

      ul {
        padding: 0;

        li {
          margin: 8px 0 8px 24px;
        }
      }
    }

    .link {
      color: #002ff2;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
