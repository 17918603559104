$transition-timer: 0.3s;
.circle{
  height: 20px;
  width: 20px;
  background-color: #00865D;
  border-radius: 50%;
  display: inline-block;
}
.tile {
  height: 164px;
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 16px;

  cursor: pointer;
  border: 2px solid transparent;
  transition: border $transition-timer;

  .content-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .tile-header {
      .label-container {
        gap: 8px;
        height: 18px;

        .label {
          border-radius: 16px;
          width: auto;
          padding-left: 5px;
          padding-right: 5px;
          display: inline-block;
          line-height: 20px;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;

          &.new {
            background-color: #dff0ff;
            color: #0173e2;
          }

          &.deprecated {
            width: 100px;
            background-color: #f9ccdb;
            color: #e20101;
          }

          &.dcsa {
            background-color: #cce7df;
            color: #07865d;
          }
          &.pilot{
            background-color: #ffffff;
            color: #04246a;
          }
          &.optimizing {
            background-color: #ffffff;
            color: #04246a;
            width:82px;
          }
          &.updated {
            background-color: #F8DFB4;
            color: #C18922;
            width: 80px;
          }
        }
      }
    }

    .name {
      height: max-content;
      text-align: left;
      font-family: Antonio-Bold, sans-serif;
      font-size: 20px;
      color: #04246a;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-bottom: 4px;
      font-weight: unset;
    }

    .description {
      margin-top: -7px;
      text-align: left;
      font-size: 14px;
      color: #04246a;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }

  .tile-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9099ab;
    line-height: 18px;
  }

  &:hover {
    border: 2px solid #3e64b8;
  }
}

.MuiTooltip-popper{
  .MuiTooltip-tooltip{
    color:black;
    font-size: 14px;
    .MuiTooltip-arrow{
      color:#fff;
    }
  }
}