#footer {
  .footer-content {
    .global-footer {
      color: #04246a;
      background-color: #f3f5f7;
      .App-content {
        .footer {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          font-size: 1rem;
          padding: 72px 54px;
          line-height: 1.5;
          gap: 16px;

          .better-way-icon {
            max-width: 100%;
          }

          .center-part {
            text-align: left;
            color: #04246a;

            .link {
              padding: 4px 0;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }

            .tiret {
              width: 70px;
              height: 4px;
              margin: 8px 0;
              background-color: #e20101;
            }

            .about-us {
              color: #04246a;
              display: block;
              text-decoration: none;
            }
          }

          .right-part {
            font-size: 24px;
            text-align: left;

            svg {
              color: #04246a;
              width: 45px;
              height: 45px;
            }
          }
        }
      }
    }
    .classic-footer {
      color: #04246a;
      background-color: #fff;
      font-size: 1rem;
      padding: 44px;
      line-height: 1.5;
      .original {
        margin-top: 42px;
      }
      ul {
        list-style: none;
        display: flex;
        align-items: center;
        padding-top: 0;
        margin: 0;
        flex-wrap: wrap;
        justify-content: center;
        gap: 96px;
        padding-inline-start: 0;

        li {
          line-height: 1;

          .button {
            color: #04246a;
            text-transform: none;
            text-decoration: none;
            cursor: pointer;
            user-select: none;

            &:hover {
              text-decoration: underline;
            }
          }

          #ot-sdk-btn {
            padding: 0;
            background-color: transparent;
            font-family: Roboto, sans-serif !important;
            border: none;
            font-size: 16px;
          }
        }

        li:hover {
          text-decoration: underline;
        }
      }
    }
    &.phone,
    &.tablet {
      .classic-footer {
        ul {
          margin: 20px 0;
          gap: 20px;
        }
      }
      .global-footer {
        svg {
          display: unset;
        }
      }
    }
    &.phone {
      .global-footer .App-content .footer {
        margin: 0;
        display: unset;
        .left-part {
          text-align: left;
        }
      }
    }
    &.tablet {
      .global-footer .App-content .footer {
        padding: 0 54px;
      }
    }
  }
}
