.parent-partner-item {
  .sub-content-container {
    .content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .covered-products-container {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;

        .covered-products-title {
          font-weight: bold;
        }

        ul,
        li {
          padding: 0;
          margin: 0;
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          column-gap: 12px;
          row-gap: 4px;

          li {
            list-style: none;

            .covered-product {
              display: flex;
              gap: 12px;

              .separator {
                border-right: 1px solid #b7c0d5;
              }
            }
          }
        }
      }
    }
  }
  .name{
    color: #04246a;
    font-size: 14px;
    overflow: auto;
    @media(min-width:1300px){
      max-width: 350px;
    }
    @media(min-width:450px) and (max-width:500px){
      max-width: 250px;
    }
    @media(min-width:375px) and (max-width:450px){
      max-width: 170px;
    }
  }
}
