.header-sub-menu {
  display: flex;
  cursor: default;
  position: absolute;
  overflow: visible;
  z-index: 3;
  top: calc(100% + 1px);
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;

  &.left {
    right: 0;
  }

  &.right {
    left: 0;
  }

  .transition-content {
    .content {
      padding: 16px 32px;
    }

    .placeholder-content {
      visibility: hidden;
      display: none;
    }

    &.expanded {
      .placeholder-content {
        display: block;
      }
    }
  }

  .sections-container {
    display: flex;
    column-gap: 48px;
  }
}

.phone,
.tablet {
  .header-sub-menu {
    position: relative;
    width: 100%;
    box-shadow: none;

    .sections-container {
      flex-wrap: wrap;
    }
  }
}
