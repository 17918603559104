$transition-timer: 0.3s;

#home-page {
  .api-portal-title {
    font: normal normal 48px/62px Antonio-Bold;

    .title-brace {
      left: 59px;
      width: 17px;
      height: 62px;
      margin-left: 6px;
      margin-right: 6px;
      text-align: left;
      font: normal normal 48px/62px Antonio-Bold;
      color: #e20101;
    }

    &.phone,
    .tablet {
      padding-top: 12px;
      height: 48px;
      font: normal normal 28px/36px Antonio-Bold;
      .title-brace {
        font: normal normal 28px/36px Antonio-Bold;
      }
    }
  }

  .api-portal-content {
    margin-top: 20px;
    font-size: 20px;
    width: 65%;

    &.phone,
    .tablet {
      margin-top: 0;
      font-size: 18px;
      width: 100%;
    }
  }

  .helpStart {
    padding: 30px 45px;
    background: #3e64b8;
    color: #fff;
    font-size: 22px;
    text-align: left;

    .lane {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;
      margin-top: 10px;

      .landingStartedLane {
        display: flex;
        gap: 36px;
        font-size: 16px;
        margin: 20px 0;

        .block {
          display: flex;
          justify-content: flex-start;

          .tiretNumber {
            text-align: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border-style: dotted;
            border-width: medium;
            margin-right: 10px;
            margin-top: -4px;
            line-height: 22px;
          }
        }

        &.phone,
        &.tablet {
          display: unset;

          .block {
            margin-bottom: 20px;
          }
        }
      }

      .learnMore {
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        width: 120px;
        background-color: #e20101;
        cursor: pointer;
        color: white;
        height: 40px;
        margin: auto;

        transition: background-color 0.3s;

        &:hover {
          background-color: #a92316;
        }
      }
    }
  }

  .products-roadmap-container {
    padding-top: 0;

    .App-content {
      padding-top: 0;
    }

    .roadmap-title {
      display: flex;
      justify-content: flex-start;
      font: normal normal 32px/48px Antonio-Bold;
      color: #04246a;
      align-items: center;
      gap: 12px;
      margin-bottom: 12px;

      svg {
        width: 44px;
        height: 6px;
      }
    }

    .products-roadmap {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      justify-content: space-between;
      margin: 0 56px;

      .content {
        font-size: 18px;
        color: #04246a;
        text-align: left;

        .italic {
          font-style: italic;
        }
      }

      .button {
        background: #e20101;
        border-radius: 0;
        color: #ffffff;
        align-items: center;
        transition: background-color 0.3s;
        width: 220px;
        height: 40px;
        line-height: 40px;

        &:hover {
          cursor: pointer;
          background-color: #a92316;
        }
      }
      &.phone {
        margin: 0 0 24px 0;
      }
    }
  }

  .api-price {
    display: none;
    visibility: hidden;
    background-color: #e6e9f0;
    padding: 4%;

    .api-price-title {
      display: flex;
      align-content: flex-start;
      font-size: large;
      font-weight: bold;
      color: #04246a;
    }

    .api-price-content {
      margin-top: 1%;
      display: flex;
      align-content: flex-start;
      font-size: small;
      color: #04246a;
    }
  }

  .api-getting-started {
    background-color: #3e64b8;
    color: white;

    .api-getting-started-title {
      font: normal normal 32px/48px Antonio-Bold;
      display: flex;

      .tiret {
        margin-top: auto;
        margin-bottom: auto;
        width: 44px;
        height: 6px;
      }

      .getting-started {
        margin-left: 20px;
        text-align: left;
        color: #ffffff;
      }
    }

    .api-getting-started-content {
      padding-block: 48px;
      display: flex;
      justify-content: flex-start;

      .api-getting-started-image {
        width: 124px;
        height: 124px;
        background-color: white;
        border-radius: 50%;

        svg {
          margin-top: 28px;
        }
      }

      .apiUsage {
        color: #ffffff;
        text-align: left;
        margin-left: 48px;

        .apiUsage-title {
          font: normal normal 20px/24px Antonio-Bold;
          margin-bottom: 24px;
        }

        .apiUsage-button {
          margin-top: 24px;
          padding: 12px 56px;
          border: 1px solid #ffffff;
          width: max-content;
          cursor: pointer;

          transition: background-color $transition-timer,
            border $transition-timer, color $transition-timer;

          &:hover {
            background-color: #ffffff;
            border: 1px solid #04246a;
            color: #04246a;
          }
        }
      }
    }
  }

  .api-sign-in {
    height: 283px;
    background: #ffffff 0 0 no-repeat padding-box;

    .api-sign-in-title {
      font-family: Antonio-Bold, sans-serif;
      font-size: 35px;
      padding-top: 36px;
      margin-left: auto;
      margin-right: auto;
      color: #04246a;
    }

    .api-sign-in-content {
      margin-top: 33px;
      margin-left: auto;
      margin-right: auto;
      width: 623px;
      height: 57px;
      text-align: center;
      color: #04246a;
    }

    .api-sign-in-button {
      margin-top: 29px;

      .buttonSignUp {
        text-transform: none;
        width: 150px;
        height: 43px;
        background: #e20101 0 0 no-repeat padding-box;
        border-radius: 0;

        .signUp {
          text-align: right;
          color: #ffffff;
        }
      }

      .buttonSignUp:hover {
        background-color: #a92316;
      }
    }
  }
}
