#subscription-request {
  position: relative;

  .block {
    text-align: left;
    margin-top: 48px;

    .title {
      color: #04246a;
      font-family: Antonio-Bold, sans-serif;
      font-size: 20px;
      letter-spacing: -0.61px;
      margin-bottom: 24px;

      &.small {
        font-size: 16px;
        letter-spacing: -0.49px;
      }
    }

    .block-content {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(calc((100% - 72px) / 4), 1fr)
      );
      gap: 24px;

      &:not(last-child) {
        margin-bottom: 24px;
      }
    }

    .MuiAutocomplete-clearIndicator {
      display: none;
    }

    .radio-container {
      display: flex;
      margin-bottom: 24px;

      .MuiFormControlLabel-root {
        margin-right: 48px;

        .MuiIconButton-root {
          margin-right: 8px;
          padding-top: 0;
          padding-bottom: 0;
        }

        .MuiFormControlLabel-label {
          user-select: none;
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    .MuiButton-root.Mui-disabled {
      color: #ffffff;
      border: 1px solid #e2afaf;
      background-color: #e2afaf;
    }

    .button {
      padding: 0;
      height: 42px;
      width: 107px;
      line-height: 42px;

      .MuiCircularProgress-root {
        color: #c19b9b;
        height: 16px !important;
        width: 16px !important;
      }
    }
  }

  &.tablet {
    .block {
      .block-content {
        grid-template-columns: repeat(
          auto-fill,
          minmax(calc((100% - 24px) / 2), 1fr)
        );
      }
    }
  }

  &.phone {
    .block {
      .block-content {
        grid-template-columns: none;
      }
    }
  }

  &.phone,
  &.tablet {
    .buttons-container {
      flex-direction: column-reverse;
      flex-wrap: wrap;

      .MuiAlert-root {
        align-self: flex-end;
        width: max-content;
      }
    }
  }
}
