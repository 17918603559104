#business-request-container {
  .form-content {
    text-align: left;
    height: max-content;

    .MuiFormGroup-root {
      gap: 24px;
      width: 100%;
      margin-top: 24px;
      opacity: 1;
      color: #04246a;

      .inputs-container {
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(calc((100% - 60px) / 4), 1fr)
        );
        justify-content: space-between;
        gap: 24px;

        &.phone {
          grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

          & > div {
            width: 100%;
          }
        }

        &.tablet {
          grid-template-columns: repeat(
            auto-fill,
            minmax(calc((100% - 40px) / 2), 1fr)
          );
        }
      }
    }

    .button-sign-up {
      justify-content: flex-end;

      .button {
        padding: 0;
        width: 220px;
        height: 51px;
        line-height: 51px;
      }
    }

    .button-container {
      margin-top: 48px;
      display: flex;
      gap: 24px;

      &.save-button {
        justify-content: flex-end;
      }

      .button {
        text-align: center;
        color: #ffffff;
        width: 86px;
        height: 42px;
        line-height: 42px;
        background-color: #e20101;
        cursor: pointer;

        transition: background-color 0.3s;

        &:hover {
          background-color: #a92316;
        }

        &.disabled {
          background-color: #e2afaf;
          cursor: default;
        }

        .loader {
          display: flex;

          .MuiCircularProgress-root {
            color: #c19b9b !important;
          }
        }
      }
    }
  }
}
