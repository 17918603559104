.header-sub-menu-section {
  height: max-content;
  width: max-content;
  padding: 0 16px;
  border-left: 2px solid #e20101;

  li {
    padding: 4px 0;
    text-align: left;
    list-style: none;

    &.title {
      font-size: 20px;
      font-family: Antonio-bold, sans-serif;
      padding: 8px 0;
    }

    &.clickable {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
