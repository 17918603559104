#commercial-offers {
  color: #04246a;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  #commercial-offers-content {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    .loader {
      margin: auto;
    }

    .buttons-container {
      margin-top: 48px;

      .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 24px;

        .button {
          padding: 8px 24px;

          .MuiButton-label svg {
            margin: -6px;
          }

          &.previous .MuiButton-label span {
            margin-left: 8px;
          }

          &.next .MuiButton-label span {
            margin-right: 8px;
          }

          &.confirm-button-placeholder {
            padding: 0;
            background-color: transparent;
            border: 1px solid transparent;
            height: 42px;
            width: 107px;
          }

          &.Mui-disabled {
            border: 1px solid #e2afaf;
            background-color: #e2afaf;
            color: #ffffff;
            cursor: default;
          }
        }
        &.phone {
          .MuiButtonBase-root {
            width: 60%;
            &.previous {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
