.braced-title {
  color: #04246a;
  font-family: Antonio-Bold, sans-serif;
  text-align: left;
  word-wrap: break-word;

  &.x-large {
    font-size: 42px;
  }

  &.large {
    font-size: 26px;
  }

  &.normal {
    font-size: 20px;
  }

  .brace {
    color: #E20101;
    margin-right: 8px;
  }
}
