#search-input {
  position: relative;
  width: 100%;
  box-shadow: 0 5px 10px 2px rgba(6, 22, 56, 0.1);
  display: flex;
  border-radius: 4px;
  text-align: left;
  font-size: 22px;

  input {
    color: #04246a;
    border: 1px solid transparent;
    padding: 16px 24px;
    flex-grow: 1;
    border-radius: 4px;

    transition: border 0.3s;

    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0;
    }

    &:focus {
      border: 1px solid #04246a;
    }
  }

  svg {
    position: absolute;
    margin: auto 0;
    top: 0;
    bottom: 0;
    right: 24px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  &.is-default-hidden {
    width: unset;
    box-shadow: unset;

    input {
      visibility: hidden;
      width: 0;
      transition: width 0.5s;
    }

    &.focussed {
      input {
        visibility: visible;
        width: 200px;
        font-size: 14px;
        border: 1px solid #04246a;
      }
    }
  }
}
