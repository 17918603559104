#set-password {
  user-select: none;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .api-image {
    padding: 5%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    background: transparent url(../../../../assets/images/image_sign_page.jpg)
      50% 50% no-repeat padding-box;
    background-size: cover;

    .api-portal,
    .api-form {
      height: max-content;
    }

    .api-portal {
      .api-portal-title {
        margin-top: 5%;
        text-align: left;
        font-family: Antonio-Bold, sans-serif;
        font-size: 48px;
        color: #ffffff;

        .title-brace {
          color: #e20101;
          margin-left: 6px;
          margin-right: 6px;
        }
      }

      .api-portal-content {
        margin-top: 19px;
        width: 60%;
        text-align: left;
        font-size: 20px;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }

  .api-form {
    width: 400px;
    background-color: #ffffff;
    padding: 48px;

    .form-title {
      font-family: Antonio-Bold, sans-serif;
      font-size: 34px;
      text-align: left;
      color: #04246a;
    }

    .form-message {
      margin-top: 16px;
      font-size: 18px;
      text-align: left;
      color: #04246a;
    }

    .form-content {
      text-align: left;
      height: max-content;
      height: 180px;

      .MuiFormGroup-root {
        width: 100%;
        margin: 24px 0;
        opacity: 1;
        color: #04246a;

        .input-container {
          position: relative;
          margin-top: 8px;
          margin-bottom: 24px;

          .MuiInputBase-root {
            width: 100%;

            input[type="password"]::-ms-reveal,
            input[type="password"]::-ms-clear {
              display: none;
            }
          }

          .visibility-icon {
            position: absolute;
            right: 12px;
            top: calc(50% - 12px);
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.3s;

            &.visible {
              opacity: 1;
            }

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }

    .requirements-container {
      color: #04246a;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      height: 230px;

      .requirement {
        display: flex;
        align-items: center;
        margin-top: 16px;
        font-size: 14px;
        &.valid {
          display: none;
        }
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .button {
      text-align: center;
      color: #ffffff;
      padding: 4px 64px;
      line-height: 42px;
      background-color: #e20101;
      cursor: pointer;

      transition: background-color 0.3s;

      &:hover {
        background-color: #a92316;
      }

      &.disabled {
        background-color: #e2afaf;
        cursor: default;
      }

      .loader {
        display: flex;
        padding: 0 59px 16px;

        .MuiCircularProgress-root {
          color: #c19b9b !important;
        }
      }
    }
  }
  &.tablet {
    .api-form {
      width: calc(100% - 92px);
      height: 100%;
    }

    .button-container.save-button {
      justify-content: center;
    }
  }

  &.phone {
    .api-form {
      padding: 24px;
      width: calc(100% - 48px);
    }

    .button-container {
      margin-top: 24px;
    }
  }
}
