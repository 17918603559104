$cma_blue: #04246a;
$cma_red: #e20101;
$cma_red_darker: #ab0000;

html,
body,
#root {
  height: 100%;
}

.App {
  text-align: center;
  font-family: Roboto, sans-serif;

  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .loader-container {
    display: flex;
    flex-direction: column;

    .loader {
      flex: 1;
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-content {
  padding: 44px 0;
  margin: 0 auto;
  width: 1272px;
}

.App-page {
  flex: 1;
  min-height: calc(100vh - 113px);
}

@media only screen and (max-width: 500px) {
  .App-content {
    width: calc(100% - 44px);
    padding: 8px 0;
  }
}

@media only screen and (min-width: 500px) {
  .App-content {
    width: calc(100% - 88px);
    padding: 22px 0;
  }
}

@media only screen and (min-width: 800px) {
  .App-content {
    width: calc(100% - 132px);
    padding: 33px 0;
  }
}

@media only screen and (min-width: 1000px) {
  .App-content {
    width: calc(100% - 176px);
    padding: 44px 0;
  }
}

@media only screen and (min-width: 1300px) {
  .App-content {
    width: 1272px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Antonio-Bold";
  font-weight: 400 !important;
  src: local("Antonio-Bold"),
    url(./assets/fonts/Antonio-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "Roboto-Bold";
  font-weight: 400 !important;
  src: local("Roboto-Bold"),
    url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Medium";
  src: local("Roboto-Medium"),
    url(./assets/fonts/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "RobotoCondensed-Bold";
  src: local("RobotoCondensed-Bold"),
    url(./assets/fonts/RobotoCondensed-Bold.ttf) format("truetype");
}

.custom-button {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid $cma_blue;
  color: $cma_blue;
  font-size: 16px;
  padding: 8px 12px;
  width: 190px;
}

.form-input {
  height: 76px;
}

div.MuiInputBase-root {
  border: solid 1px #6b7790;
  height: 40px;
  background-color: #ffffff;

  transition: border 0.3s;

  &::before,
  &::after {
    content: unset;
  }

  &.Mui-focused {
    border: solid 1px $cma_blue;
  }

  &.Mui-error {
    border: solid 1px #e2014b;
  }

  input {
    height: 100%;
    padding: 0 12px;
    color: $cma_blue !important;
  }
}

.MuiPaper-root {
  border: 1px solid #04246a;
  .MuiList-padding {
    padding: 0;
  }
  .MuiPaper-rounded {
    border-radius: unset;
  }
  .MuiListItem-root.Mui-disabled {
    border-bottom: solid 1px #04246a;
    background-color: #fff;
    color: #04246a;
    opacity: 1;
    color: #fff;
    .Mui-selected {
      background-color: #fff;
      color: #04246a;
    }
  }
  .MuiListItem-root.Mui-selected {
    background-color: #235af6;
    color: #fff;
    &:hover {
      background-color: #235af6;
      color: #fff;
    }
  }
}

span.MuiIconButton-root {
  padding-right: 0;
  margin-right: -4px;
  color: #6b7790;
  transition: color 0.3s;

  &.Mui-checked {
    color: $cma_blue;
  }

  &:hover {
    background-color: transparent !important;
  }

  &:not(.MuiCheckbox-root) .MuiTouchRipple-root > span {
    display: none;
  }
}

.MuiMenu-list,
.autocomplete-item {
  color: $cma_blue;
}

.autocomplete-item {
  display: flex;
  align-items: center;
  gap: 8px;

  &.search-option {
    display: none;
  }
}

.MuiAutocomplete-paper {
  width: 100%;

  .MuiAutocomplete-option {
    padding: 0;

    & > .autocomplete-item {
      margin: 6px 16px;
      width: 100%;

      .loader {
        margin: 0;
      }
    }
  }
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  font-size: 16px;
  color: #6b7790;
  background-color: #ffffff;
  box-shadow: 0 5px 10px 2px rgba(6, 22, 56, 0.1);
  max-width: unset;
}

li {
  list-style-position: inside;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
  -webkit-text-fill-color: #04246a;
}

.MuiButton-root.button {
  text-transform: none;
  border-radius: 0 !important;
  text-align: center;
  border: 1px solid $cma_red;
  background-color: $cma_red;
  color: #ffffff;

  transition: background-color 0.3s, border 0.3s;

  &:hover {
    background-color: $cma_red_darker;
    border: 1px solid $cma_red_darker;
  }

  &.inverted {
    background-color: #ffffff;
    color: $cma_red;

    &:hover {
      background-color: #eaeaea;
    }
  }

  .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//region SCROLLBARS
.track-vertical {
  top: 2px;
  bottom: 2px;
  right: 2px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0);
  display: block;
  width: 0.4vw !important;

  .thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    height: 80%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.track-horizontal {
  display: none;
}

.view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
//endregion
