$transition-timer: 0.3s;

.catalog-title {
  font: normal normal 32px/48px Antonio-Bold;
  color: #04246a;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  .title {
    line-height: 32px;
  }

  svg {
    width: 44px;
    height: 6px;
  }
  #search {
    box-shadow: none;
  }
}

.sub-content {
  margin: 0 56px;
}

.details {
  font-size: 18px;
  color: #04246a;
  text-align: left;
  line-height: 28px;
}

.api-catalog-title,
.event-catalog-title {
  display: flex;
  align-items: center;
  margin: 48px 0 24px;
  font: normal normal 26px/32px Antonio-Bold;
  color: #04246a;

  .title-brace {
    margin-right: 4px;
    font-size: 26px;
    color: #e20101;
  }

  svg {
    width: 66px;
    height: 9px;
  }
}

.api-catalog-content,
.event-catalog-content {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc((100% - 48px) / 3), 1fr)
  );
  justify-content: space-between;
  gap: 24px;
  margin: 24px 0;
  color: #04246a;

  .api-family-tile,
  .event-family-tile {
    display: flex;
    padding: 10px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 6px;
    box-shadow: 0 0 6px #294a8933;

    transition: border $transition-timer;

    .image-container {
      margin: auto 0;

      .image {
        margin-right: 20px;
        transition: margin $transition-timer, width $transition-timer,
          height $transition-timer;
      }
    }

    .content-tile {
      overflow: hidden;
      padding: 12px 0;

      .name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -4px;
        text-align: left;
        font-family: Antonio-Bold, sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: #04246a;
        height: 100%;
        transition: font-size $transition-timer, height $transition-timer;
      }

      .explore {
        color: #235af6;
        font-weight: bold;
        opacity: 0;
        display: flex;
        transition: opacity 0.1s;
        margin-top: 12px;

        &:hover {
          transition: opacity $transition-timer;
          opacity: 0.7 !important;
        }

        .more {
          width: 16px;
          height: 16px;
          color: #235af6;
          border-radius: 20px;
          border: solid 1px #235af6;
          margin-right: 10px;
        }
      }
    }

    &:hover {
      border: 2px solid #3e64b8;

      .content-tile {
        .name {
          font-size: 20px;
          height: 30px;
        }

        .explore {
          opacity: 1;
        }
      }
    }
  }

  &.phone,
  &.tablet {
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc((100% - 24px) / 2), 1fr)
    );

    .api-family-tile,
    .event-family-tile {
      display: unset;

      .image-container {
        .image {
          margin: 0;
        }
      }

      .content-tile {
        .name {
          font-size: 18px;
          text-align: center;
        }

        .explore {
          display: none;
        }
      }

      svg {
        height: 48px;
        width: 48px;
      }
    }
  }

  &.tablet {
    .api-family-tile,
    .event-family-tile {
      .content-tile {
        .name {
          font-size: 24px;
        }
      }
    }
  }
}

.App-content.phone{
  .catalog-title {
    margin-top: 24px;
  }
  .sub-content {
    margin: 0;
  }
}
