.checkbox-input {
  display: flex;
  align-items: center;
  gap: 6px;
  user-select: none;

  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 4px;
    color: #04246a;

    .MuiIconButton-root {
      padding: 0;
      margin-right: 8px;

      &.error {
        color: #d02d4f;
      }

      .MuiIconButton-label {
        height: 22px;
        width: 22px;
      }
    }
  }

  .error-icon {
    color: #d02d4f;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    height: 20px !important;
    width: 20px !important;
    cursor: pointer;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
