.toast {
  user-select: none;
  top: 17px;
  bottom: unset !important;

  a {
    color: #ffffff;
    opacity: 1;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}
