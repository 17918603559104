.section {
  border: 1px solid #b7c0d5;
  margin-top: 32px;

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 20px;
    cursor: pointer;
    color: #04246a;

    & > div {
      display: flex;
      align-items: center;
      gap: 4px;

      .MuiSvgIcon-root {
        transition: transform 0.3s;

        &.opened {
          transform: rotate(-180deg);
        }
      }
    }
  }

  .section-content {
    overflow: hidden;
    max-height: 0;
    border-top: 1px solid transparent;
    transition: max-height 0.3s ease-out, border-top 0.3s ease-out;

    &.opened {
      max-height: 5000px;
      border-top: 1px solid #b7c0d5;
      transition: max-height 0.3s ease-in;
    }
  }
}
