.search-sort-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
  margin: 32px 0 16px;

  #search-input {
    max-width: 160px;
    box-shadow: unset;
    height: 30px;
    border: solid 1px #04246a;

    input {
      width: 100%;
      padding: 8px 12px;

      &:focus-visible {
        outline: unset;
      }

      &:focus {
        border: 1px solid transparent;
      }
    }

    svg {
      right: 8px;
      height: 16px;
      width: 16px;
    }
  }
}

.phone {
  .search-sort-container {
    margin: 16px 0 8px;
  }
}
