$transition-timer: 0.3s;

#faq {
  color: #04246a;

  .header-banner-content {
    display: flex;
    align-items: center;
    column-gap: 48px;

    #search-input {
      width: 30%;
    }
  }

  .summary {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 60px;
  }

  .questions {
    margin-top: 40px;

    .braced-title {
      font-size: 24px;
      color: #04246a;
      margin-bottom: 20px;
    }
  }

  &.tablet,
  &.phone {
    .header-banner-content {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;

      #search-input {
        width: 100%;
      }
    }
    .summary {
      margin: 0;
    }
  }
}
