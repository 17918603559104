.header-tab {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 32px;
  cursor: pointer;
  height: 80px;
  color: #04246a;
  transition: background-color 0.3s;

  &.clickable {
    overflow: visible;
  }

  &:hover {
    background-color: #e6e9f0;
  }

  .placeholder,
  .active-under {
    height: 4px;
    width: 100%;
  }

  &.active.computer {
    .active-under {
      animation: animate 0.3s ease-in;
      background-color: #e20101;
    }
  }

  .tab-title {
    display: flex;
    justify-content: flex-start;
  }

  .free-trial {
    margin-left: 6px;
    color: #0173e2;

    &.ended {
      color: #e2014b;
    }
  }

  @keyframes animate {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  &.phone,
  &.tablet {
    padding: 12px 0;
    height: auto;

    &:not(:last-child) {
      border-bottom: solid 1px #f3f5f7;
    }

    &:hover {
      background-color: #ffffff;
    }

    .tab-title {
      padding: 12px 24px;
      justify-content: space-between;

      .chevron {
        transition: transform 0.3s;

        &.opened {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
