$transition-timer: 0.3s;

.family-block {
  width: 100%;

  .question {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    color: #0173e2;
    cursor: pointer;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);

    .question-title {
      font-size: 18px;
      line-height: 24px;
      text-align: left;
    }

    .MuiSvgIcon-root {
      transition: transform $transition-timer;

      &.opened {
        transform: rotate(-180deg);
      }
    }
  }

  .content {
    text-align: left;
    font-size: 16px;
    margin: 8px 0 32px;

    .solution {
      color: #04246a;
      text-align: left;
      padding: 0 32px;

      ul,
      ol {
        margin: 0;
        padding: 0;

        li {
          margin: 6px 0 0 12px;

          &.main {
            margin-top: 8px;
            margin-bottom: 16px;
          }

          p {
            margin-left: 24px;
          }
        }
      }
    }
  }
}
