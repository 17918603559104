#release-note {
  margin-top: 4px;
  .release-block {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #235af6;
    transition: opacity 0.3s;
    padding: 10px 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    svg {
      color: #04246a;
    }
    .date-version {
      display: flex;
    }
  }
  .change {
    margin: 10px 5px;
    color: #04246a;
  }
}
