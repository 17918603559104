#packages-stepper {
  margin-top: 24px;
  height: 76px;

  .steps-container {
    display: flex;
    align-items: center;

    .step {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      user-select: none;

      &:hover .badge {
        opacity: 0.8;
      }

      &:first-child {
        align-items: start;
      }

      &:last-child {
        align-items: end;
      }

      .badge {
        position: relative;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 2px solid #04246a;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        background-color: #ffffff;
        color: #04246a;
        font-size: 18px;
        cursor: pointer;

        .completed-badge {
          visibility: hidden;
          width: 16px;
          height: 16px;
          position: absolute;
          top: -4px;
          right: -4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #01875e;
          color: #ffffff;

          & > svg {
            font-size: 14px;
          }
        }
      }

      .title {
        position: absolute;
        top: 44px;
        color: #04246a;
        font-family: Antonio-Bold, sans-serif;
        font-size: 18px;
        width: max-content;
        cursor: pointer;
      }

      &.active {
        .badge {
          background-color: #04246a;
          color: #ffffff;
        }

        .title {
          font-size: 26px;
        }
      }

      &.completed {
        .completed-badge {
          visibility: visible;
        }
      }
    }

    .separator {
      margin: 0 8px;
      border-top: 1px solid #d9deea;
      border-bottom: 1px solid #d9deea;
      flex-grow: 1;
    }
  }
  &.phone {
    height: auto;
    .steps-container {
      display: unset;
      .step {
        margin-bottom: 60px;
        align-items: center;
      }
      .separator {
        display: none;
      }
    }
  }
}
