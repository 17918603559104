.partner-parent-list {
  .list-header {
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  .list-content {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    @media screen and (min-width: 1300px) {
      overflow: auto; /* Show overflow */
    }
    transition: max-height 0.3s ease-out;

    &.opened {
      max-height: 650px;
      transition: max-height 0.3s ease-in;
    }
  }
  .list-content::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

/* Track (the area where the scrollbar runs) */
.list-content::-webkit-scrollbar-track {
    background: #FFFFFF; /* Color of the track */
}

/* Handle (the draggable part of the scrollbar) */
.list-content::-webkit-scrollbar-thumb {
    background: #FFFFFF; /* Color of the handle */
    border-radius: 5px; /* Roundness of the handle */
}

/* Handle on hover */
.list-content::-webkit-scrollbar-thumb:hover {
    background: #FFFFFF; /* Color of the handle on hover */
}

  .branch-list {
    margin-top: 32px;
  }
}
