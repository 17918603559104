.autocomplete-input .MuiAutocomplete-hasClearIcon {
  .MuiAutocomplete-inputRoot {
    padding-right: 38px !important;

    .MuiAutocomplete-input {
      padding: 2px 12px !important;
    }
  }

  .error div.MuiInputBase-root {
    border-color: #d02d4f;
  }
}
