.text-input {
  .MuiInputBase-root {
    display: flex;
  }

  .MuiInput-root.Mui-disabled {
    background-color: #f8f9fa;

    .MuiInputBase-input.Mui-disabled {
      opacity: 0.7 !important;
      cursor: not-allowed;
    }
  }
}
.password{
  font-size: 20px !important;
}
