#terms-and-conditions-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  line-height: 21px;
  background-color: #ffffff;

  .no-file {
    text-align: center;
    width: max-content;

    .not-found {
      position: relative;

      span {
        margin-top: 0;
      }
    }
  }

  #pdf-container {
    height: calc(100vh - 113px);
    display: flex;
    justify-content: center;
    align-items: center;

    .no-file {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      & > span {
        color: #d4d4d4;
        font-size: 18px;
        font-weight: bold;
      }

      .download {
        width: max-content;
        padding: 12px 24px;
        cursor: pointer;
        transition: background-color 0.3s;

        svg {
          margin-left: 8px;
        }

        &:hover {
          background-color: #04246a;
          color: #ffffff;
        }
      }
    }
  }
}
