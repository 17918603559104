#credentialsGuide {
    text-align: left;
    flex: 1;
  
    .content-container {
      display: flex;
      justify-content: space-between;
      margin-top: 48px;
  
      .content {
        padding-right: 48px;
        flex: 5;
        color: #04246a;
  
        .category {
          .header {
            font-size: 20px;
            margin-bottom: 24px;
  
            font-family: Antonio-bold, sans-serif;
            &.large {
              font-weight: bold;
              font-size: 24px;
              font-family: Antonio-bold, sans-serif;
            }
          }
  
          .title {
            font-size: 20px;
            margin: 24px 0 12px;
            display: flex;
            justify-content: flex-start;
            .circle {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              border-style: dotted;
              font-size: 12px;
              color: #e20101;
              margin-right: 8px;
              background-color: white !important;
              .number {
                margin: 0 10px 0 10px;
                font-family: Antonio-Bold, sans-serif;
                font-size: 18px;
                color: #04246a;
              }
            }
          }
  
          .message {
            line-height: 24px;
            font-size: 16px;
            margin-bottom: 4px;
  
            p {
              margin: 24px 0;
            }
  
            &.italic {
              font-style: italic;
            }
          }
  
          ul {
            margin: 0;
            padding: 0;
  
            li {
              margin-left: 24px;
            }
          }
        }
      }
    }
    .banner{
      display: flex;
      background-color: #DFF0FF;
      padding: 10px;
      font-size: 16px;
    }
    .banner-info{
      line-height: 24px;
    }
    .banner-icon{
      margin-right:10px;
    }
  }
  
  @media (max-height: 900px) {
    #credentialsGuide {
      .head {
        height: 100px;
      }
    }
  }
  