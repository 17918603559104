#api-status {
  flex: 1;

  .header {
    text-align: left;
    color: #04246a;
    padding-bottom: 0;

    ul {
      padding-left: 24px;
    }
  }

  #status-iframe {
    height: 800px;
    border: 0;
    margin: 3rem -62px 0 -62px;
  }

  @media only screen and (max-width: 500px) {
    #status-iframe {
      width: calc(100% - 22px + 62px);
      padding-bottom: 8px;
    }
  }

  @media only screen and (min-width: 500px) {
    #status-iframe {
      width: calc(100% - 88px + 62px);
      padding-bottom: 22px;
    }
  }

  @media only screen and (min-width: 800px) {
    #status-iframe {
      width: calc(100% - 132px + 62px);
      padding-bottom: 33px;
    }
  }

  @media only screen and (min-width: 1000px) {
    #status-iframe {
      width: calc(100% - 176px + 62px);
      padding-bottom: 44px;
    }
  }

  @media only screen and (min-width: 1300px) {
    #status-iframe {
      width: calc(1272px + 62px);
    }
  }
}
