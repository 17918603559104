.App-content {
  .api-endpoints-list {
    $transition-timer: 0.3s;

    &.computer {
      padding: 44px;
    }

    .list-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    .list-container {
      position: relative;
      flex-grow: 1;

      &.empty {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .item {
          color: #04246a;
          background-color: white;

          .item-header-container {
            display: flex;
            justify-content: space-between;
            align-content: center;
            padding: 24px;
            cursor: pointer;

            .title {
              font-size: 20px;
              line-height: 24px;
              font-family: Antonio-Bold, sans-serif;
            }

            .MuiSvgIcon-root {
              transition: transform $transition-timer;

              &.opened {
                transform: rotate(-180deg);
              }
            }
          }

          .content {
            padding: 0 24px 24px;

            .MuiTableRow-head {
              .MuiTableCell-root {
                border-bottom: 1px solid #b7c0d5;
              }

              .method-cell {
                width: 10%;
              }

              .name-cell {
                width: 25%;
              }

              .summary-cell {
                width: 50%;
              }

              .found-in-field-cell {
                width: 15%;
              }
            }

            .MuiTableCell-root {
              font-size: 16px;
              color: #6b7790;

              div {
                border-left: 1px solid #a8b2cc;
                padding-left: 12px;
              }

              .find-icon {
                margin-top: 2px;
                width: 20px;
                height: 20px;
                cursor: pointer;
                transition: opacity $transition-timer;

                &:hover {
                  opacity: 0.7;
                }
              }
            }

            .MuiTableBody-root {
              .MuiTableRow-root {
                &:nth-child(odd) {
                  background-color: #f8f9fa;
                }

                .MuiTableCell-body {
                  color: #04246a;
                }
              }
            }
          }
        }
      }

      .notfound {
        .message {
          color: #6b7790;
        }

        .image {
          width: 64px;
          height: 64px;

          .a {
            fill: #f8f9fa;
          }

          .b,
          .c {
            fill: #6b7790;
          }

          .content1,
          .font1 {
            stroke: #6b7790;
          }
        }
      }
    }

    &.phone,
    &.tablet {
      width: 100%;
    }
  }
}
