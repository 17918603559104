#header {
  user-select: none;
  line-height: 1.5;
  color: #04246a;
  z-index: 12;

  .header-bottom-content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    height: 80px;
    border-bottom: 1px solid rgba(107, 119, 144, 0.3);

    .left-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .headerPhone {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .burger,
      .placeholder {
        width: 20px;
      }

      button {
        width: 100px !important;
        height: 30px !important;
        margin: 0 -5px 5px !important;
        font-size: 12px !important;
        float: right;
      }

      .header-bottom-logo-icon {
        margin-right: 0;
      }

      .global {
        padding: 0;
        margin: 0;

        .under {
          position: relative;
          top: 5px;
        }

        svg {
          margin-bottom: -6px;
        }
      }
    }

    .burger-menu {
      z-index: 5;
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      border: 1px #031745 solid;
      background-color: white;

      .blue-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
        height: 42px;
        background-color: #04246a;
        color: white;

        svg {
          fill: white;
        }
      }

      .custom-scrollbars {
        height: calc(100vh - 90px) !important;
      }
    }

    .header-bottom-logo-icon {
      cursor: pointer;
      width: 94px;
      height: 58px;
      margin-right: calc(48px - 12px);
    }
    .connection-block {
      .header-bottom-menu {
        display: flex;
      }

      .signIn {
        padding: 12px 0;
        margin: 24px;
        cursor: pointer;
        color: #fff;
        background-color: #e20101;
        border-bottom: 1px solid #f3f5f7;
      }

      .gettingStarted {
        padding: 12px 0;
        margin: 24px;
        cursor: pointer;
        border: 1px solid #e20101;
        color: #e20101;
      }

      &.phone,
      &.tablet {
        .header-bottom-menu {
          flex-direction: column;
        }
      }
      &.tablet {
        .signIn,
        .gettingStarted {
          margin: 24px auto;
          width: 60%;
        }
      }
    }
  }
}
