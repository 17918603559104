.side-panel {
  background-color: #ffffff;
  position: fixed;
  width: 45vw;
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 1;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 13;

  #api-description,
  #event-description {
    .App-content {
      width: auto;
    }

    .back-container {
      padding: 16px 0;
    }

    .back,
    .container {
      padding: 24px 48px;
    }

    #user-subscribed {
      display: none;
    }
  }

  #event-description {
    .code-cell {
      min-width: 12%;
      width: 17% !important;
    }
  }

  &.phone,
  &.tablet {
    width: 100%;
  }
}
