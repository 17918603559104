div[role="presentation"] {
  display: flex;
  justify-content: center;
  align-items: center;

  #delete-profile-popup {
    height: calc(312px - 2 * 48px);
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #ffffff;
    padding: 48px;

    .popup-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 48px;
      background-color: #ffffff;

      .title {
        text-align: center;
        font-size: 36px;
        line-height: 1.5;
        font-family: Antonio-bold, sans-serif;
        color: #04246a;
      }

      .message {
        text-align: center;
        color: #04246a;
        font-size: 20px;
      }

      .buttons-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 32px;
        row-gap: 16px;
        justify-content: center;

        .button {
          cursor: pointer;
          padding: 12px 20px 12px 20px;
          transition: opacity 0.3s;

          &:hover {
            opacity: 0.7;
          }

          &.confirm-button {
            color: #ffffff;
            background: #e20101;
          }

          &.cancel-button {
            color: #e20101;
            background: #ffffff;
            border: solid 1px #e20101;
          }
        }
      }
    }

    &.phone {
      padding: 24px;
      height: calc(100vh - 48px);
      width: calc(100vw - 48px);
    }
  }
}
