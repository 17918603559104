.not-found {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #d4d4d4;
  font-weight: bold;

  & > svg {
    width: 256px;
  }

  span {
    margin-top: 16px;
  }
}
