.grecaptcha-badge {
  display: none;
}

#register-form {
  flex: 1;
  display: flex;
  flex-direction: column;

  .contentRegister {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .infoMessage {
      margin-bottom: 48px;
      padding: 16px 0;
      background-color: #dff0ff;
      border-radius: 5px;
      display: flex;
      align-items: center;

      &.warning {
        background-color: #f2c059;
        svg {
          color: #04246a;
        }
      }

      svg {
        margin: 0 24px;
      }

      .contentMessage {
        text-align: left;
        color: #04246a;
        line-height: 18px;
        font-size: 14px;
        flex: 1;

        .bold {
          font-weight: bold;
        }

        .italic {
          font-style: italic;
        }
      }

      .close {
        cursor: pointer;
      }
    }

    .br {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      margin: 40px 0;
    }

    .form-title {
      font-family: Antonio-Bold, sans-serif;
      text-align: start;
      font-size: 20px;
      color: #04246a;
      margin-bottom: 24px;
    }

    .form-content {
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(calc((100% - 72px) / 4), 1fr)
      );
      gap: 24px;
    }

    .confirm {
      display: flex;
      flex-wrap: wrap;
      column-gap: 20px;
      margin: 48px 0 0 0;
      justify-content: space-between;

      .button-container {
        justify-content: flex-end;
        display: flex;
        gap: 24px;

        .button {
          width: 96px;
          height: 42px;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          &.disabled {
            height: 18px;
            width: 56px;
            color: #ffffff50;
            background: #e2010150;
            cursor: pointer;
            border-radius: 0;
            text-transform: none;
            padding: 12px 20px 12px 20px;

            .MuiCircularProgress-root {
              color: #c19b9b;
            }
          }
        }
      }
    }

    &.phone {
      .form-content {
        grid-template-columns: none;
      }
    }

    &.tablet {
      .form-content {
        grid-template-columns: repeat(
          auto-fill,
          minmax(calc((100% - 48px) / 2), 1fr)
        );
      }
    }

    &.phone,
    &.tablet {
      .confirm {
        .terms-conditions-container {
          margin-bottom: 20px;
        }

        .button-container {
          flex-direction: row-reverse;
          flex-wrap: wrap;
        }
      }
    }
  }

  .button {
    cursor: pointer;
    color: #ffffff;
    background: #e20101;
    border-radius: 0;
    text-transform: none;
    padding: 12px 20px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #a92316;
    }
  }

  .finalMessage {
    .form-title {
      font-family: Antonio-Bold, sans-serif;
      font-size: 20px;
      color: #04246a;
      text-align: center;
      margin-bottom: 40px;
    }

    .form-message {
      font-size: 18px;
      color: #04246a;
      text-align: center;
      margin-bottom: 40px;
    }

    .button-container .button {
      margin: 0 auto;
      width: max-content;
    }
  }
}

.champCompanyType {
  .MuiInputBase-root {
    padding-top: 10px;
    padding-left: 0;
  }
}

.selector {
  div {
    padding-left: 10px;
    padding-bottom: 10px !important;
  }
}
